import { t } from 'i18next';
import React from 'react'
import { Link } from 'react-router-dom'
import { COLORS, SIZES } from '../../constants';
import { useNavigate } from 'react-router-dom';
export default function OrderDownloadCard({
    order
}) {

    const navigate = useNavigate();

    console.log('order download card ---', order)
    const extend_order_goods = order.extend_order_goods;
    const good = extend_order_goods[0]
    const state = order.state;

    function renderButtons()
    {
        if(state === 10) {
            return (
                <button 
                    type="button" 
                    className="btn btn-danger pill px-4"
                    onClick={() => navigate(`/payment/${order.pay_sn}`)}
                    >
                    {t("pay")}{" "}
                    <span className="icon-right icon ms-0">
                    {" "}
                    <i className="las la-arrow-right" />
                    </span>
                </button>
            )
        }

        if(state === 20) {
            return <button 
                    type="button" 
                    className="btn btn-main pill px-4"
                    onClick={() => {
                        window.open(order?.resource?.link)
                    }}
                    >
                    {t("download")}{" "}
                    <span className="icon-right icon ms-0">
                    {" "}
                    <i className="las la-download" />
                    </span>
                </button>
        }
    }

  return (
    <div className="download-item flx-between gap-3">
        <div className="download-item__content flx-align flex-nowrap gap-3 flex-grow-1">
        <div className="download-item__thumb flex-shrink-0">
            <img src={good?.goods_img} alt="" />
        </div>
        <div className="download-item__info">
            <div
                style={{
                    marginBottom:SIZES.base,
                    color: COLORS.darkBlue
                }}
            >
                <Link to={`/customer-order-detail/${order?.sn}`} target='__blank' className="link">
                    {order.sn}
                </Link>
            </div>
            <h6 className="download-item__title mb-1">
                <Link to={`/product-details/${good?.sn}`} target='__blank' className="link">
                    {good?.goods_title}
                </Link>
            </h6>
            <Link
            to="#"
            className="download-item__text text-main mb-3 font-12 hover-text-decoration-underline"
            >
            {good?.goods_spec.map((spec) => {
                return `${spec.name}: ${spec.value_name}`
            })}
            </Link>
            <h6 className="download-item__title mb-1">
            <Link to={`/product-details/${good?.sn}`} target='__blank' className="link">
                {order?.state_desc}
            </Link>
            </h6>
        </div>
        </div>
        <div className="download-item__right flex-shrink-0 d-inline-flex flex-column gap-2 align-items-center">
            {/*  */}
            { renderButtons() }
        </div>
    </div>
  )
}
