import React, { useState } from "react";
import BrandSectionOne from "../components/brand/BrandSectionOne";
import Payment from "../components/Payment";
import BreadcrumbPayment from "../components/breadcrumb/BreadcrumbPayment";
import BaseLayout from "../layout/BaseLayout";
import * as paynameApi from '../api/payname'
import { useEffectOnce } from "../utils/func";
import { useParams } from "react-router-dom";
import * as orderApi from '../api/order'
import { useNavigate } from "react-router-dom";
import { icons } from "../constants/icons";
import { t } from "i18next";
import SEO from "../components/SEO";
const PaymentPage = () => {

  let params = useParams();

  const pay_sn = params.pay_sn;

  const navigate = useNavigate()

  const [orderInfo,setOrderInfo] = useState(null)
  
  useEffectOnce(() => {
    loadInfo()
  })

  async function loadInfo()
  {
    const response = await orderApi.info({ pay_sn })
    console.log('25 here order info is',response)
    if(response && response.code === 0) {
      setOrderInfo(response.result.info)
    }
  }

  const stepData = [
    {
      id: 1,
      url: "/all-product",
      icon: icons.process_white1, 
      title: t("shopping.product"),
      enable: true,
    },
    {
      id: 1,
      url: "#",
      icon: icons.process2 , 
      title:t("payment.methods"),
      enable: true,
    },
    {
      id: 2,
      url: "#",
      icon: icons.process3,
      title:t("preview.order"),
      enable: false 
    },
    {
      id: 2,
      url: "#",
      icon: icons.process4,
      title:t("download.code"),
      enable: false 
    },
  ];

  const [paynameList,setPaynameList] = useState([])

  useEffectOnce(() => {
    loadPaynames()
  })

  async function loadPaynames() {
    const response = await paynameApi.list()
    console.log('35 payname response is', response)
    if(response && response.code === 0 ) {
      setPaynameList(response.result.list)
    }
  }

  return (

    <BaseLayout>

      <SEO 
        title={t("payment")}
      />

      {/* BreadcrumbSix */}
      <BreadcrumbPayment 
        stepData={stepData}
      />

      {/* CartPayment */}
      {
        paynameList && paynameList.length > 0 && (
          <Payment 
            paynameList={paynameList}
            productName={orderInfo?.extend_order_goods[0]?.goods_title}
            amount={orderInfo?.amount}
            sn={orderInfo?.sn}
            pay_sn={pay_sn}
            paypay_client_id={orderInfo?.paypay_client_id}
            onNavigate={(sn) => { 
              console.log('92 onNavigate sn',sn, orderInfo)
              if(sn) {
                navigate(`/customer-order-detail/${sn}`) 
              }
              
            }}
        />
        )
      }
      

      {/* BrandSectionOne */}
      <BrandSectionOne />
    </BaseLayout>
  );
};

export default PaymentPage;
