import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { locale } from "../utils/time";
import { gradient, thumbs } from "../constants/icons";
import { useTranslation } from "react-i18next";
import * as resourceApi from '../api/goodsResource'
import { useEffectOnce } from "../utils/func";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const OrderDetail = ({
  sn,
  pay_sn,
  state,
  state_desc,
  paymentMethod,
  createTime,
  amount,
  orderGoods
}) => {

  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [info,setInfo] = useState({
    id:0,
    link: ""
  })


  useEffectOnce(() => {
    loadResourceInfo()
  })

  async function loadResourceInfo() {
    const response = await resourceApi.info({ pay_sn })

    if(response && response.code === 0) {
      const info = response.result.info
      setInfo(info)
    }
  }

  const rows = [
    {
      title: t("order.no"),
      value:sn
    },
    {
      title:t("order.status"),
      value:state_desc
    },
    {
      title:t("pay.method"),
      value:paymentMethod
    },
    {
      title:t("order.date"),
      value: locale(createTime,i18n.language)
    },
    // {
    //   title:"Subtotal",
    //   value: `$${amount}`
    // },
    {
      title: t("order.total"),
      value: `${amount}`
    }
  ]

  function renderButtons() {

    if(state === 10) {
      return (
        <Link
          to={`/payment/${pay_sn}`}
          className="btn btn-danger flx-align gap-2 pill"
        >
          {t("pay")}
          <span className="icon line-height-1 font-20">
            <i className="las la-arrow-right" />
          </span>
        </Link>
      )
    }

    if(state === 20) {
      return (
        <Link
          to={info.link}
          target="_blank"
          className="btn btn-danger flx-align gap-2 pill"
          onClick={() => {
            // loadResourceInfo()
          }}
        >
          {t("download")}
          <span className="icon line-height-1 font-20">
            <i className="las la-arrow-down" />
          </span>
        </Link>
      )
    }

    return (
      <div></div>
    )
  }

  return (
    <section className="cart-thank section-bg padding-y-120 position-relative z-index-1 overflow-hidden">
      <img
        src={gradient.thank_you_gradient}
        alt=""
        className="bg--gradient"
      />
      <div className="container container-two">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="cart-thank__content text-center">
              <h2 className="cart-thank__title mb-48">
                {t("thank.you.for.purchased.this.products", { product:orderGoods && orderGoods[0].goods_title})}
                {/* Thank you for purchased this products!! */}
              </h2>
              <div className="cart-thank__img">
                <img src={thumbs.thank_evenelope} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="padding-t-120">
          <div className="cart-thank__box">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="thank-card">
                  <h5 className="thank-card__title mb-3">{t("order.detail")}</h5>
                  <ul className="list-text">
                    {
                      rows.map((item,index) => {
                        return (
                          <li className="list-text__item flx-align flex-nowrap" key={`row-${index}`}>
                            {/* <span className="text text-heading fw-500"> */}
                            <span className="text-heading fw-500 text">
                              {item?.title}:
                            </span>
                            <span className="text">{item?.value}</span>
                          </li>
                        )
                      })
                    }
                    
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="thank-card">
                  <h5 className="thank-card__title mb-3">
                    
                    {t("products.you.have.purchased")}
                  </h5>
                  <ul className="list-text">
                    <li className="list-text__item flx-align flex-nowrap">
                      <span className="text text-heading fw-500 font-heading fw-700 font-18">
                        
                        {t("product.name")}
                      </span>
                      <span className="text text-heading fw-500">{t("price")}</span>
                    </li>
                    {
                      orderGoods && orderGoods?.length > 0 && orderGoods.map((item,index) => {
                        return (
                          <li className="list-text__item flx-align flex-nowrap" key={`or-${index}`}>
                            <span className="text text-heading fw-500">
                              {item?.goods_title}
                            </span>
                            <span className="text">{item?.goods_price}</span>
                        </li>
                        )
                      })
                    }
                    
                  </ul>
                  <div className="thank-card__thumb mt-64 mb-3">
                    
                  </div>
                  <div className="flx-between gap-2">
                    
                    {renderButtons()}
                    <Link
                      to="/customer-orders"
                      className="btn btn-main flx-align gap-2 pill"
                    >
                      {t("back.to.list")}
                      <span className="icon line-height-1 font-20">
                        <i className="las la-arrow-right" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default OrderDetail;