
import {
    get,
    post
} from '../utils/request'

// Axios Get Call - Get all jokes categories.
export const register = async (params) => {
    try {
        const response = await post("/auth/register", params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}

export const login = async (params) => {
    try {
        const response = await post("/auth/login", params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}

export const info = async (params) => {
    try {
        const response = await get("/user/self", params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}