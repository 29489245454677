


import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useEffectOnce } from "../utils/func";
import * as baseApi from '../api/base'
import * as userApi from '../api/user'
import * as footerApi from '../api/footer'
import { useTranslation } from "react-i18next";
import { REDDIRECT_URL, TOKEN } from "../constants";
import { icons, logo } from "../constants/icons";
import DashboardNav from "../components/dashboard/DashboardNav";
import DashboardSidebar from "../components/dashboard/DashboardSidebar";
import DashboardFooter from '../components/dashboard/DashboardFooter'
import { useNavigate } from "react-router-dom";
import { getRedirectUrl } from "../utils";
import SEO from "../components/SEO";
const MasterLayout = ({ seo_title,seo_description,seo_keywords, children, onBaseInfo }) => {

    let [active, setActive] = useState(false)
    let [show, setShow] = useState(false)

    const { i18n,t } = useTranslation()

    const navigate = useNavigate()

    const [base,setBase] = useState({
        company_address: "",
        contact_number: "",
        description: "",
        domain: "",
        email: "",
        favicon:"",
        formspree_id: "",
        keywords:"",
        logo: "",
        map_url:"",
        site_name:""
    })

    const [footerInfo,setFooterInfo] = useState({
        company_name: "",
        beian: "",
        description: "",
        data: [],
        menu: []
    })

    const [userInfo,setUserInfo] = useState({
        id: 0
    })

    let dashboardControl = () => {
        setActive(!active)
    }
    let showProfileControl = () => {
        setShow(!show)
    }

    console.log('show MasterLayout')

    useEffect(() => {
        console.log('one')
    })

    
    useEffect(() => {
        console.log('two')
    },[])

    
    useEffect(() => {
        console.log('three')

        return () => {

        }
    },[])

    useEffectOnce(() => {
        console.log('use effect once called...')
        loadBaseInfo()
        loadFooterInfo()
        loadUserInfo()
        return () => {

        }
    }, [])

    async function loadBaseInfo() {
        const response = await baseApi.info()
        console.log('loadbaseinfo is----------', response)
        if(response && response.code == 0) {
            const info = response.result.info
            setBase(info)
            if( onBaseInfo && typeof onBaseInfo === 'function')
            {
                onBaseInfo(info)
            }
        } 
    }

    async function loadFooterInfo() {
        const response = await footerApi.baseInfo()
        if(response && response.code === 0) {
            setFooterInfo(response.result.info)
        }
    }
    
    async function loadUserInfo() {
        const response = await userApi.info()
        if(response && response.code === 0) {
            setUserInfo(response.result.info)
        } else {
            const redirectUrl = getRedirectUrl();
            localStorage.setItem(REDDIRECT_URL, redirectUrl);
            navigate("/login");
        }
    }

    function renderMobileMenu()
    {
        return (
            <div className="mobile-menu d-lg-none d-block" >
                <button type="button" className="close-button text-body hover-text-main" >
                    {" "}
                    <i className="las la-times" />{" "}
                </button>
                <div className="mobile-menu__inner">
                    <Link to="/" className="mobile-menu__logo">
                        <img
                            src={base.log}
                            alt="Logo"
                            className="white-version"
                        />
                        <img
                            src={base.logo}
                            alt="Logo"
                            className="dark-version"
                        />
                    </Link>
                    <div className="mobile-menu__menu">
                        <ul className="nav-menu flx-align nav-menu--mobile">
                            <li className="nav-menu__item has-submenu">
                                <Link to="#" className="nav-menu__link">
                                    Home
                                </Link>
                                <ul className="nav-submenu">
                                    <li className="nav-submenu__item">
                                        <Link to="/" className="nav-submenu__link">
                                            {" "}
                                            Home One
                                        </Link>
                                    </li>
                                     <li className="nav-submenu__item">
                                        <Link to="/index-two" className="nav-submenu__link">
                                            {" "}
                                            Home Two
                                        </Link>
                                    </li> 
                                </ul>
                            </li>
                            <li className="nav-menu__item has-submenu">
                                <Link to="/#" className="nav-menu__link">
                                    Products
                                </Link>
                                <ul className="nav-submenu">
                                    <li className="nav-submenu__item">
                                        <Link to="/all-product" className="nav-submenu__link">
                                            {" "}
                                            All Products
                                        </Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/product-details" className="nav-submenu__link">
                                            {" "}
                                            Product Details
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-menu__item has-submenu">
                                <Link to="/#" className="nav-menu__link">
                                    Pages
                                </Link>
                                <ul className="nav-submenu">
                                    <li className="nav-submenu__item">
                                        <Link to="/profile" className="nav-submenu__link">
                                            {" "}
                                            Profile
                                        </Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/cart" className="nav-submenu__link">
                                            {" "}
                                            Shopping Cart
                                        </Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/cart-personal" className="nav-submenu__link">
                                            {" "}
                                            Mailing Address
                                        </Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/cart-payment" className="nav-submenu__link">
                                            {" "}
                                            Payment Method
                                        </Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/cart-thank-you" className="nav-submenu__link">
                                            {" "}
                                            Preview Order
                                        </Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/dashboard" className="nav-submenu__link">
                                            {" "}
                                            Dashboard
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-menu__item has-submenu">
                                <Link to="/#" className="nav-menu__link">
                                    Blog
                                </Link>
                                <ul className="nav-submenu">
                                    <li className="nav-submenu__item">
                                        <Link to="/blogs" className="nav-submenu__link">
                                            {" "}
                                            Blog
                                        </Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/blog-details" className="nav-submenu__link">
                                            {" "}
                                            Blog Details
                                        </Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/blog-details-sidebar" className="nav-submenu__link">
                                            {" "}
                                            Blog Details Sidebar
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/contact" className="nav-menu__link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                        <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
                            <Link to="/register" className="btn btn-main pill">
                                <span className="icon-left icon">
                                    <img src="assets/images/icons/user.svg" alt="" />
                                </span>
                                Create Account
                            </Link>
                            <div className="language-select flx-align select-has-icon">
                                <img
                                    src="assets/images/icons/globe.svg"
                                    alt=""
                                    className="globe-icon white-version"
                                />
                                <img
                                    src="assets/images/icons/globe-white.svg"
                                    alt=""
                                    className="globe-icon dark-version"
                                />
                                <select className="select py-0 ps-2 border-0 fw-500">
                                    <option value={1}>Eng</option>
                                    <option value={2}>Bn</option>
                                    <option value={3}>Eur</option>
                                    <option value={4}>Urd</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            
        )
    }

    return (
        <>
            <SEO 
                title={seo_title}
                description={seo_description}
                keywords={seo_keywords}
            />
            {/* { renderMobileMenu() } */}

            <section className={`dashboard ${active && "active"}`} onClick={() => show === true && setShow(false)}>
                <div className="dashboard__inner d-flex">
                    {/* Dashboard Sidebar Start */}
                    <div className={`dashboard-sidebar ${active && "active"}`}>
                        <button
                            type="button"
                            className="dashboard-sidebar__close d-lg-none d-flex text-body hover-text-main" onClick={dashboardControl}
                        >
                            <i className="las la-times" />
                        </button>
                        <div className="dashboard-sidebar__inner">
                            <Link to="/" className="logo mb-48">
                                <img
                                    // src={logo.logo}
                                    src={base.logo}
                                    alt=""
                                    className="white-version"
                                />
                                <img
                                    src={base.logo}
                                    alt=""
                                    className="dark-version"
                                />
                            </Link>
                            <Link to="/" className="logo favicon mb-48">
                                <img src={logo.favicon} alt="" />
                            </Link>
                            {/* Sidebar List Start */}
                            
                            <DashboardSidebar />
                            {/* Sidebar List End */}
                        </div>
                    </div>

                    <div className="dashboard-body">
                        {/* Dashboard Nav Start */}
                        <DashboardNav 
                            show={show}
                            showProfileControl={showProfileControl}
                            dashboardControl={dashboardControl}
                            userId={userInfo?.id}
                            userAvatar={userInfo?.profile?.avatar}
                        />


                        {/* children */}
                        {children}
                        {/* Dashboard Footer */}
                        
                        <DashboardFooter 
                            company_name={footerInfo?.company_name}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default MasterLayout;