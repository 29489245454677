import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import * as buyApi from '../../api/buy'
import { Button, Space, message, Drawer } from 'antd';
export default function SettlePaypal({
    visible,
    onClose,
    pay_sn,
    paypal_client_id,
    onNavigate
}) {
    const initialOptions = {
        "client-id": paypal_client_id
    };

    function renderPaypal() {
        return (
            <PayPalScriptProvider options={initialOptions} >
                <PayPalButtons 
                    style={{ 
                        layout: "vertical",
                        shape: "rect",
                        //color:'blue' change the default color of the buttons
                        layout: "vertical", //default value. Can be changed to horizontal
                        }} 
                    createOrder={async (data) => {
                        console.log('createOrder 108',data)
                        try {
                            const response = await buyApi.createOrderByPaypal({ pay_sn })
                            console.log('1111 create payapl repsonse',response)
                            return response.result;
                        } catch (error) {
                            throw new Error(error); 
                        }
                        
                    }}
                    onApprove={async (data,actions) =>  {
                        console.log('on approve 117',data)
                        try {
                            const payload = {
                                paypal_order_id: data.orderID
                            }
                            const response = await buyApi.captureOrderByPaypal(payload)
                            console.log('captureorder response is', response)
                            if(response && response.code === 0) {
                                console.log('这里要跳转到订单详情页面')
                                onNavigate()
                            }
                        } catch (error) {
                            throw new Error(error)
                        }
                    }}
                    onCancel={() => {
                        console.log('on paypal cancel')
                        onNavigate()
                    }}
                    />
            </PayPalScriptProvider>
        )
    }


  return (
    <Drawer
        title={"Paypal"}
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={visible}
        key={"bottom"}
        style={{
            // display:'flex',
            // alignItems: 'center'
        }}
    >

        { renderPaypal() }
        { renderPaypal() }
    </Drawer>
    
  )
}
