import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import ScrollToTop from "react-scroll-to-top";
import HomePageTwo from "./pages/HomePageTwo";
import AllProductPage from "./pages/products/AllProductPage";
import ProductDetailsPage from "./pages/products/ProductDetailsPage";

import CartPage from './pages/cart/cart'
import CartPersonalPage from "./pages/cart/cartPersonalPage";
import PaymentPage from "./pages/PaymentPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import DashboardProfilePage from "./pages/dashboard/DashboardProfilePage";

// Customer 
import CustomerOrderPage from './pages/dashboard/CustomerOrderPage'
import CustomerOrderDetailPage from './pages/dashboard/CustomerOrderDetailPage'
import CustomerRefundPage from './pages/dashboard/CustomerRefundPage'
import CustomerSettingPage from './pages/dashboard/CustomerSettingPage'

import EarningPage from './pages/dashboard/EarningPage'

// Distributor

import DistributorOrdersPage from './pages/dashboard/DistributorOrdersPage'
import DistributorOrderDetailPage from './pages/dashboard/DistributorOrderDetailPage'
import DistributorUserPage from './pages/dashboard/DistributorUserPage'

import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import BlogPage from "./pages/blogs/BlogPage";
import BlogDetailsPage from "./pages/blogs/BlogDetailsPage";
import BlogDetailsSidebarPage from "./pages/blogs/BlogDetailsSidebarPage";
import ContactPage from "./pages/ContactPage";
import { IntlProvider } from "react-intl";
import { ToastContainer } from 'react-toastify';
import "./i18n"
function App() {

  return (
    <>
        <ToastContainer />
        <BrowserRouter>
        <RouteScrollToTop />
        <ScrollToTop smooth color="#A847F0" />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/index-two" element={<HomePageTwo />} />
          <Route exact path="/all-product" element={<AllProductPage />} />
          <Route exact path="/all-product/:id" element={<AllProductPage />} />
          <Route exact path="/product-details/:id" element={<ProductDetailsPage />} />
        
          <Route exact path="/cart" element={<CartPage />} />
          <Route exact path="/cart-personal" element={<CartPersonalPage />} />
          <Route exact path="/payment/:pay_sn" element={<PaymentPage />} />
          
          <Route exact path="/dashboard" element={<DashboardPage />} />
          {/* <Route exact path="/dashboard-profile" element={<DashboardProfilePage />} /> */}

          {/* Customer */}
          <Route exact path="/customer-orders" element={<CustomerOrderPage />} />
          <Route exact path="/customer-order-detail/:id" element={<CustomerOrderDetailPage />} />
          <Route exact path="/customer-order-refund/:id" element={<CustomerRefundPage />} />
          <Route exact path="/customer-setting" element={<DashboardProfilePage />} />
        
          {/* earning */}
          <Route exact path="/earning" element={<EarningPage />} />

          {/* distributor  */}
          <Route exact path="/distributor-orders" element={<DistributorOrdersPage />} />
          <Route exact path="/distributor-order-detail/:id" element={<DistributorOrderDetailPage />} />
          <Route exact path="/distributor-users" element={<DistributorUserPage />} />

          {/* <Route exact path="/order/:sn" element={<OrderPage />} /> */}

          {/* <Route exact path="/profile" element={<ProfilePage />} />
          <Route exact path="/profile/:id" element={<ProfilePage />} />
          <Route exact path="/follower" element={<FollowerPage />} />
          <Route exact path="/following" element={<FollowingPage />} />
          <Route exact path="/setting" element={<SettingPage />} />
          <Route exact path="/orders" element={<OrderPage />} />
          <Route exact path="/earning" element={<EarningPage />} />
          <Route exact path="/review" element={<ReviewPage />} />
          <Route exact path="/download" element={<DownloadPage />} />
          <Route exact path="/refund" element={<RefundPage />} /> */}


          <Route exact path="/blogs" element={<BlogPage />} />
          <Route exact path="/blog-details/:id" element={<BlogDetailsPage />} />
          <Route exact path="/blog-details-sidebar/:id" element={<BlogDetailsSidebarPage />} />
          <Route exact path="/contact" element={<ContactPage />} />

          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/login" element={<LoginPage />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
