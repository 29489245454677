import { Link } from "react-router-dom";
import Stepper from "../stepper";
import { gradient, shapes } from "../../constants/icons";
import { t } from "i18next";

const BreadcrumbPayment = ({
  stepData
}) => {
  return (
    <section className="breadcrumb breadcrumb-four padding-static-y-60 section-bg position-relative z-index-1 overflow-hidden">
      <img
        src={gradient.breadcrumb_gradient_bg}
        alt=""
        className="bg--gradient"
      />
      <img
        src={shapes.element_moon3} 
        alt=""
        className="element one"
      />
      <img
        src={shapes.element_moon1}
        alt=""
        className="element three"
      />
      <div className="container container-two">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="breadcrumb-four-content">
              <h3 className="breadcrumb-four-content__title text-center mb-3 text-capitalize">
                {t("payment")}
              </h3>
              <ul className="breadcrumb-list flx-align justify-content-center gap-2 mb-2">
                <li className="breadcrumb-list__item font-14 text-body">
                  <Link
                    to="/"
                    className="breadcrumb-list__link text-body hover-text-main"
                  >
                    {t("home")}
                  </Link>
                </li>
                <li className="breadcrumb-list__item font-14 text-body">
                  <span className="breadcrumb-list__icon font-10">
                    <i className="fas fa-chevron-right" />
                  </span>
                </li>
                <li className="breadcrumb-list__item font-14 text-body">
                  <span className="breadcrumb-list__text">{t("payment")}</span>
                </li>
              </ul>
              <Stepper 
                data={stepData}
              />
              
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default BreadcrumbPayment;