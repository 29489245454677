import React from 'react'
import { useTranslation } from 'react-i18next'
import { TOKEN } from '../../constants'
import { icons } from '../../constants/icons'
import { NavLink } from 'react-router-dom'

export default function DashboardSidebar() {

    const { t, i18n } = useTranslation()

    const menus = [
        {
            id: 1,
            title: t("dashboard"),
            link: "/dashboard",
            icon: icons.sidebar_icon1,
            icon_active: icons.sidebar_icon1_active
        },
       
        {
            id: 2,
            title: t("orders"),
            link: "/customer-orders",
            icon: icons.sidebar_icon12,
            icon_active: icons.sidebar_icon12_active 
        },
        {
            id: 2,
            title: t("distributor.users"),
            link: "/distributor-users",
            icon: icons.sidebar_icon2,
            icon_active: icons.sidebar_icon2_active 
        },
        {
            id: 4,
            title: t("distributor.orders"),
            link: "/distributor-orders",
            icon: icons.sidebar_icon5,
            icon_active: icons.sidebar_icon5_active 
        },
        {
            id: 2,
            title: t("distributor.earnings"),
            link: "/earning",
            icon: icons.sidebar_icon11,
            icon_active: icons.sidebar_icon11_active 
        },
        {
            id: 4,
            title: t("settings"),
            link: "/customer-setting",
            icon: icons.sidebar_icon10,
            icon_active: icons.sidebar_icon10_active 
        },
    ];

  return (
    <ul className="sidebar-list">
        {
            menus.map((menu,index) => {
                return (
                    <li className="sidebar-list__item" key={`menu-${index}`}>
                        <NavLink to={menu.link} className={(navData) =>
                            navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
                        }  >
                            <span className="sidebar-list__icon">
                                <img
                                    src={menu.icon}
                                    alt=""
                                    className="icon"
                                />
                                <img
                                    src={menu.icon_active}
                                    alt=""
                                    className="icon icon-active"
                                />
                            </span>
                            <span className="text">{menu.title}</span>
                        </NavLink>
                    </li>
                )
            })
        }
        
        {/* <li className="sidebar-list__item">
            <NavLink to="/dashboard-profile" className={(navData) =>
                navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
            }>
                <span className="sidebar-list__icon">
                    <img
                        src="assets/images/icons/sidebar-icon2.svg"
                        alt=""
                        className="icon"
                    />
                    <img
                        src="assets/images/icons/sidebar-icon-active2.svg"
                        alt=""
                        className="icon icon-active"
                    />
                </span>
                <span className="text">{t("profile")}</span>
            </NavLink>
        </li>
        <li className="sidebar-list__item">
            <NavLink to="/follower" className={(navData) =>
                navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
            }>
                <span className="sidebar-list__icon">
                    <img
                        src="assets/images/icons/sidebar-icon4.svg"
                        alt=""
                        className="icon"
                    />
                    <img
                        src="assets/images/icons/sidebar-icon-active4.svg"
                        alt=""
                        className="icon icon-active"
                    />
                </span>
                <span className="text">Followers</span>
            </NavLink>
        </li>
        <li className="sidebar-list__item">
            <NavLink to="/following" className={(navData) =>
                navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
            }>
                <span className="sidebar-list__icon">
                    <img
                        src="assets/images/icons/sidebar-icon5.svg"
                        alt=""
                        className="icon"
                    />
                    <img
                        src="assets/images/icons/sidebar-icon-active5.svg"
                        alt=""
                        className="icon icon-active"
                    />
                </span>
                <span className="text">Followings</span>
            </NavLink>
        </li>
        <li className="sidebar-list__item">
            <NavLink to="/setting" className={(navData) =>
                navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
            }>
                <span className="sidebar-list__icon">
                    <img
                        src="assets/images/icons/sidebar-icon10.svg"
                        alt=""
                        className="icon"
                    />
                    <img
                        src="assets/images/icons/sidebar-icon-active10.svg"
                        alt=""
                        className="icon icon-active"
                    />
                </span>
                <span className="text">Settings</span>
            </NavLink>
        </li> */}
        {/* <li className="sidebar-list__item">
            <NavLink to="/statement" className={(navData) =>
                navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
            }>
                <span className="sidebar-list__icon">
                    <img
                        src="assets/images/icons/sidebar-icon12.svg"
                        alt=""
                        className="icon"
                    />
                    <img
                        src="assets/images/icons/sidebar-icon-active12.svg"
                        alt=""
                        className="icon icon-active"
                    />
                </span>
                <span className="text">Statements</span>
            </NavLink>
        </li> */}
        
        {/* <li className="sidebar-list__item">
            <NavLink to="/review" className={(navData) =>
                navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
            }>
                <span className="sidebar-list__icon">
                    <img
                        src="assets/images/icons/sidebar-icon7.svg"
                        alt=""
                        className="icon"
                    />
                    <img
                        src="assets/images/icons/sidebar-icon-active7.svg"
                        alt=""
                        className="icon icon-active"
                    />
                </span>
                <span className="text">Reviews</span>
            </NavLink>
        </li> */}
        {/* <li className="sidebar-list__item">
            <NavLink to="/download" className={(navData) =>
                navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
            }>
                <span className="sidebar-list__icon">
                    <img
                        src="assets/images/icons/sidebar-icon6.svg"
                        alt=""
                        className="icon"
                    />
                    <img
                        src="assets/images/icons/sidebar-icon-active6.svg"
                        alt=""
                        className="icon icon-active"
                    />
                </span>
                <span className="text">Downloads</span>
            </NavLink>
        </li>
        <li className="sidebar-list__item">
            <NavLink to="/refund" className={(navData) =>
                navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
            }>
                <span className="sidebar-list__icon">
                    <img
                        src="assets/images/icons/sidebar-icon8.svg"
                        alt=""
                        className="icon"
                    />
                    <img
                        src="assets/images/icons/sidebar-icon-active8.svg"
                        alt=""
                        className="icon icon-active"
                    />
                </span>
                <span className="text">Refunds</span>
            </NavLink>
        </li> */}
        <li className="sidebar-list__item">
            <NavLink 
                to="/login" 
                onClick={() => {
                    localStorage.removeItem(TOKEN)
                }}
                className={(navData) =>
                navData.isActive ? "sidebar-list__link activePage" : "sidebar-list__link"
                
            }>
                <span className="sidebar-list__icon">
                    <img
                        src={icons.sidebar_icon13}
                        alt=""
                        className="icon"
                    />
                    <img
                        src={icons.sidebar_icon13_active}
                        alt=""
                        className="icon icon-active"
                    />
                </span>
                <span className="text">{t("logout")}</span>
            </NavLink>
        </li>
    </ul>
  )
}
