import React, { useState } from "react";
import Preloader from "../../helper/Preloader";
import MasterLayout from "../../layout/MasterLayout";
import Distributor from "../../components/DashboardFollowing";
import { useEffectOnce } from "../../utils/func";
import * as distributorApi from '../../api/distributor'
import DistributorCustomerList from "../../components/distributor/DistributorCustomerList";
import { t } from "i18next";

const DistributorUserPage = () => {

  const [result,setResult] = useState({
    list: [],
    total_number: 0
  })

  useEffectOnce(() => {
    loadList()
  },[])

  async function loadList(params = {}){
    const response = await distributorApi.customers(params)
    if(response && response.code === 0) {
      setResult(response.result)
    }
  } 

  return (

    <>
      <MasterLayout
        seo_title={t("distributor.user.list")}
        seo_description={""}
      >
        {/* Preloader */}
        <Preloader />

        {/* DashboardFollowing */}
        <DistributorCustomerList 
          list={result.list}
          total_number={result.total_number}
          onFilter={params => loadList(params)}
        />

      </MasterLayout>

    </>
  );
};

export default DistributorUserPage;
