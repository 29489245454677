import Pagination from '../pagination'
import DistributorCustomerCard from './DistributorCustomerCard';

const DistributorCustomerList = ({
  list,
  total_number,
  onFilter
}) => {
  return (
    <div className="dashboard-body__content">
    <div className="card common-card">
      <div className="card-body">
        <div className="follow-wrapper">
          {
             list && list.length > 0 && list.map((item,index) => {
                return (
                  <DistributorCustomerCard 
                    item={item}
                    key={`distributor-cs-${index}`}
                  />
                )
             })
          } 
          

        </div>
      </div>
    </div>

    <Pagination 
          totalNumber={total_number}
          onFilter={onFilter}
        />
  </div>
  
  );
}

export default DistributorCustomerList;