import React from 'react'
import { Link } from 'react-router-dom'
import { GOOD_TYPE_NORMAL } from '../../constants'
import { useTranslation } from 'react-i18next'
import { icons, thumbs } from '../../constants/icons'
import { locale } from '../../utils/time'

export default function ProductDetailSidebar({
    type,
    currentSku,
    seckillGoods,
    handleCheckout,
    isCheckouting,
    supplier,
    tags,
    features,
    compatibilities,
    includefiles,
    techframeworks,
    fileSize,
    categoryLabel,
    createTime,
    updateTime,
}) {
    const { i18n,t } = useTranslation()


    function renderSupplier() {
        return (
            <div className="author-details">
            <div className="d-flex align-items-center gap-2">
                <div className="author-details__thumb flex-shrink-0">
                    <img src={supplier?.profile_img} alt="" />
                </div>
                <div className="author-details__content">
                    <h6 className="author-details__name font-18 mb-2">
                        <Link to={`/profile?id=${supplier?.id}`} className="link hover-text-main">
                            {supplier?.title}
                        </Link>
                    </h6>
                    <span className="d-flex align-items-center gap-1">
                        <span className="star-rating">
                            <span className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </span>
                            <span className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </span>
                            <span className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </span>
                            <span className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </span>
                            <span className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </span>
                        </span>
                        <span className="star-rating__text text-body"> 5.0</span>
                    </span>
                </div>
            </div>
            <ul className="badge-list flx-align gap-2 mt-3">
                <li
                    className="badge-list__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Badge Info"
                >
                    <img src={thumbs.badge1} alt="" />
                </li>
                <li
                    className="badge-list__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Badge Info"
                >
                    <img src={thumbs.badge2} alt="" />
                </li>
                <li
                    className="badge-list__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Badge Info"
                >
                    <img src={thumbs.badge3} alt="" />
                </li>
                <li
                    className="badge-list__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Badge Info"
                >
                    <img src={thumbs.badge4} alt="" />
                </li>
                <li
                    className="badge-list__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Badge Info"
                >
                    <img src={thumbs.badge5} alt="" />
                </li>
                <li
                    className="badge-list__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Badge Info"
                >
                    <img src={thumbs.badge6} alt="" />
                </li>
                <li
                    className="badge-list__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Badge Info"
                >
                    <img src={thumbs.badge7} alt="" />
                </li>
                <li
                    className="badge-list__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Badge Info"
                >
                    <img src={thumbs.badge8} alt="" />
                </li>
            </ul>
            <Link
                to={`/profile?id=${supplier?.id}`}
                className="btn btn-outline-light w-100 pill mt-32"
            >
                {t("view.portfolio")}
            </Link>
        </div>
        )
    }

  return (
    <div className="product-sidebar section-bg">
        <div className="product-sidebar__top position-relative flx-between gap-1">
            <button
                type="button"
                className="btn-has-dropdown font-heading font-18"
            >
                {t("extended.license")}
            </button>
            {/* <div className="license-dropdown">
                <div className="license-dropdown__item mb-3">
                    <h6 className="license-dropdown__title font-body mb-1 font-16">
                        Regular License
                    </h6>
                    <p className="license-dropdown__desc font-13">
                        Use, by you or one client, in a solitary finished result which
                        end clients are not charged for. The complete cost
                        incorporates the thing cost and a purchaser expense..
                    </p>
                </div>
                <div className="license-dropdown__item">
                    <h6 className="license-dropdown__title font-body mb-1 font-16">
                        Extended License
                    </h6>
                    <p className="license-dropdown__desc font-13">
                        Use, by you or one client, in a solitary final result which
                        end clients can be charged for. The all out cost incorporates
                        the thing cost and a purchaser expense.
                    </p>
                </div>
                <div className="mt-3 pt-2 border-top text-center ">
                    <Link
                        to="#"
                        className="link hover-text-decoration-underline font-14 text-main fw-500"
                    >
                        View License Details
                    </Link>
                </div>
            </div> */}
            <h6 className="product-sidebar__title">${ type == GOOD_TYPE_NORMAL ? currentSku?.price : seckillGoods && seckillGoods[0]?.seckill_price}</h6>
        </div>
        {  }
        <ul className="sidebar-list">
            <li className="sidebar-list__item flx-align gap-2 font-14 fw-300 mb-2">
                <span className="icon">
                    <img src={icons.check_cirlce} alt="" />
                </span>
                <span className="text">{t("quality.verified")}</span>
            </li>
            <li className="sidebar-list__item flx-align gap-2 font-14 fw-300 mb-2">
                <span className="icon">
                    <img src={icons.check_cirlce} alt="" />
                </span>
                <span className="text">{t("use.for.a.single.project")}</span>
            </li>
            <li className="sidebar-list__item flx-align gap-2 font-14 fw-300">
                <span className="icon">
                    <img src={icons.check_cirlce} alt="" />
                </span>
                <span className="text">{t("paying.users.only")}</span>
            </li>
        </ul>
        <div className="flx-between mt-3">
            <div className="common-check mb-0">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="checkbox"
                    id="extended"
                />
                <label
                    className="form-check-label mb-0 fw-300 text-body"
                    htmlFor="extended"
                >
                    {t("extended.support.12.month")}
                </label>
            </div>
            <div className="flx-align gap-2">
                <span className="product-item__prevPrice text-decoration-line-through">
                    $12
                </span>
                <h6 className="product-item__price mb-0 font-14 fw-500">$0</h6>
            </div>
        </div>
        <button
            type="button"
            className="btn btn-danger d-flex w-100 justify-content-center align-items-center gap-2 pill px-sm-5 mt-32"
            onClick={() => handleCheckout()}
            disabled={isCheckouting}
        >
            
            <img src={icons.add_to_cart} alt="loading" />
            
            { isCheckouting ? t("checkouting")  : t("checkout.now")}
        </button>
        {/* Author Details Start*/}
        { renderSupplier() }

        {/* Author Details End */}
        {/* Meta Attribute List Start */}
        <ul className="meta-attribute">
            <li className="meta-attribute__item">
                <span className="name">{t("last.update")}</span>
                <span className="details">{locale(updateTime, i18n.language)}</span>
            </li>
            <li className="meta-attribute__item">
                <span className="name">{t("published")}</span>
                <span className="details">{locale(createTime, i18n.language)}</span>
            </li>
            <li className="meta-attribute__item">
                <span className="name">{t("categories")}</span>
                <span className="details">{categoryLabel}</span>
            </li>
            {/* <li className="meta-attribute__item">
                <span className="name">Widget Ready</span>
                <span className="details">Yes</span>
            </li>
            <li className="meta-attribute__item">
                <span className="name">High Resolution</span>
                <span className="details">Yes</span>
            </li> */}
            <li className="meta-attribute__item">
                <span className="name">{t("copatible.with")}</span>
                <span className="details">
                    {
                        compatibilities && compatibilities.length > 0 && compatibilities.map((item,index) => {
                            return (
                                <Link 
                                    key={`com-${index}`}
                                    to="#" 
                                    className="hover-text-decoration-underline">
                                    {" "}{item?.title},
                                </Link>
                            )
                        })
                    }
                    
                </span>
            </li>
            <li className="meta-attribute__item">
                <span className="name">{t("file.size")}</span>
                <span className="details">{fileSize}</span>
            </li>
            <li className="meta-attribute__item">
                <span className="name">{t("framework")}</span>
                <span className="details">
                {
                        techframeworks && techframeworks.length > 0 && techframeworks.map((item,index) => {
                            return (
                                <Link 
                                    key={`tech-${index}`}
                                    to="#" 
                                    className="hover-text-decoration-underline">
                                    {" "}{item?.title},
                                </Link>
                            )
                        })
                    }
                </span>
            </li>
            <li className="meta-attribute__item">
                <span className="name">{t("software.features")}</span>
                <span className="details">
                    {
                        features && features.length > 0 && features.map((feature,index) => {
                            return (
                                <Link
                                    key={`feature-${index}`} 
                                    to="#" 
                                    className="hover-text-decoration-underline">
                                    {feature.title}
                                </Link>
                            )
                        })
                    }
                </span>
            </li>
            <li className="meta-attribute__item">
                <span className="name">{t("marketplace.files.included")}</span>
                <span className="details">
                    {
                        includefiles && includefiles.length > 0 && includefiles.map((file,index) => {
                            return (
                                <Link
                                    key={`feature-${index}`} 
                                    to="#" 
                                    className="hover-text-decoration-underline">
                                    {" "}{file?.title},
                                </Link>
                            )
                        })
                    }
                    
                </span>
            </li>
            <li className="meta-attribute__item">
                <span className="name">{t("layout")}</span>
                <span className="details">{t("responsive")}</span>
            </li>
            <li className="meta-attribute__item">
                <span className="name">{t("tag")}</span>
                <span className="details" style={{ display: 'flex', justifyContent:'space-between'}}>
                    {
                        tags && tags.length > 0 && tags.map((tag,index) => {
                            return  <Link 
                                        key={`tag-${index}`}
                                        to={`/all-product?tag=${tag}`}
                                        className="hover-text-decoration-underline"
                                        
                                        >
                            {" "}{tag?.title},
                        </Link>
                        })
                    }
                </span>
            </li>
        </ul>
        {/* Meta Attribute List End */}
    </div>
  )
}
