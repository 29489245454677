import React, { useState } from 'react'
import { Link } from 'react-router-dom'
export default function SocialLink({
     list
}) {


  return (
    <ul className="social-icon-list colorful-style">
        {
            list && list.map((item,index) => {
                return (
                    <li className="social-icon-list__item" key={`li-${index}`}>
                        <Link
                            to={item?.url}
                            target='__blank'
                            className="social-icon-list__link  font-16 flex-center"
                        >
                            <i className={`fab ${item.name}`} />
                        </Link>
                    </li>
                )
            })
        }
    </ul>
  )
}
