import React, { useState } from "react";
import BrandSectionOne from "../../components/brand/BrandSectionOne";
import OrderDetail from "../../components/OrderDetail";
import BaseLayout from "../../layout/BaseLayout";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "../../utils/func";
import * as orderApi from '../../api/order'
import MasterLayout from "../../layout/MasterLayout";
import SEO from "../../components/SEO";
import { t } from "i18next";
const OrderPage = () => {

  let params = useParams();
  const sn = params.id;

  const [info,setInfo] = useState(null)

  useEffectOnce(() => {

    loadInfo()
  })

  async function loadInfo(){
   
    const response = await orderApi.info({ sn })
    
    if(response && response.code === 0) {
      setInfo(response.result.info)
    }
  }

  console.log('31 order detail is', info)

  return (

    <>
      <MasterLayout>

            <SEO 
              title={`${t("order.detail")}: ${info?.order_type_desc}`}
              description={info?.state_desc}
            />
        
          {/* OrderThankYou */}
          {
            info?.pay_sn && (
              <OrderDetail 
                  sn={sn}
                  pay_sn={info?.pay_sn}
                  state={info?.state}
                  state_desc={info?.state_desc}
                  paymentMethod={info?.payment_code_desc}
                  createTime={info?.create_time}
                  amount={info?.amount}
                  orderGoods={info?.extend_order_goods}
                />
            )
          }
          
          
          {/* BrandSectionOne */}
          {/* <BrandSectionOne /> */}

      </MasterLayout>

    </>
  );
};

export default OrderPage;
