import { t } from 'i18next';
import React from 'react'

export default function Pagination({
    totalNumber,
    onFilter
}) {

    const pages = Math.round(totalNumber / 12);
    const arr = Array.from({ length: pages }, (_, i) => i + 1);

    const handlePageChange = (page) => {
        console.log('handle page change',page)
        const param = {
          page
        }
        onFilter(param)
    }

    const handleNextPage = () => {
        console.log('handle next page')
      }

    return (
        <nav aria-label="Page navigation example">
          <ul className="pagination common-pagination">
            {
              arr.map((item,index) => {
                return (
                  <li className="page-item" key={`page-${index}`}>
                    <div className="page-link" onClick={() => { handlePageChange(item)}}>
                      {item}
                    </div>
                  </li>
                )
              })
            }
            <li className="page-item">
              <div
                className="page-link flx-align gap-2 flex-nowrap"
                onClick={() => handleNextPage()}
              >
                
                {t("pagination.next")}
                <span className="icon line-height-1 font-20">
                  <i className="las la-arrow-right" />
                </span>
              </div>
            </li>
          </ul>
        </nav>
      )
}
