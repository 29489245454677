import React from 'react'
import { locale } from '../../utils/time'
import { useTranslation } from 'react-i18next'
export default function DistributorCustomerCard({
    item
}) {

    const { t,i18n } = useTranslation()

  return (
    <div className="follow-item">
        <div className="follow-item__author">
            <div className="d-flex align-items-start gap-2">
            <div className="author-details__thumb flex-shrink-0">
                <img src={item?.user_avatar} alt="" />
            </div>
            <div className="author-details__content">
                <h6 className="author-details__name font-18 mb-2">{item?.user_nickname}</h6>
                
            </div>
            </div>
        </div>
        <div className="follow-item__meta">
            <strong className="font-11 fw-600 text-heading">
            {`${t("member.since")}: ${locale(item.create_time, i18n.language)}`}
            <br />
            {`${t("state")} : ${item.state === 1 ? t("valid") : t("invalid")}`}
            </strong>
        </div>
        <div className="follow-item__sales">
            <div className="sales">
                <span className="sales__text mb-1 font-13 text-heading fw-500">
                    {t("deal.amount")}
                </span>
                <h6 className="sales__amount mb-0 font-body">{item?.total_deal_amount}</h6>
                <span className="sales__text mb-1 font-13 text-heading fw-500">
                    {t("deal.num")}
                </span>
                <h6 className="sales__amount mb-0 font-body">{item?.total_deal_num}</h6>
            </div>
        </div>
    </div>
  )
}
