
import React, { useEffect, useState } from 'react'
import ImageAds from './ImageAds'
import RichText from './RichText'
import Title from './Title'
import Text from './Text'
import Code from './Code'
import GoodsList from './GoodsList'
import Image from './Image'

export default function PageBody({
    body
}) {
  
  return (
    <div
      style={{
        marginTop: '-25px'
      }}
    >
      {
        body && body.length > 0 && body.map((item,index) => {

          if(item.type === "title") {
            return (
              <Title
                key={`title-${index}`}
                options={item.options}
                data={item.data}
              />
            )
          }

          if(item.type === "text") {
            return (
              <Text
                key={`title-${index}`}
                options={item.options}
                data={item.data}
              />
            )
          }

          if(item.type === "code") {
            return (
              <Code
                key={`title-${index}`}
                options={item.options}
                data={item.data}
              />
            )
          }

          if(item.type === "goods_list") {
            return (
              <GoodsList 
                key={`goodslist-${index}`}
                options={item.options}
                data={item.data}
              />
            )
          }

          if(item.type === "image_ads") {
            return (
              <ImageAds
                key={`imageads-${index}`}
                options={item.options}
                data={item.data}
              />
            )
          }

          if(item.type === "image") {
            return (
              <Image 
                key={`image-${index}`}
                url={item.value.url}
              />
            )
          }

          if(item.type === "rich_text") {
            return (
              <RichText 
                key={`rich-text-${index}`}
                options={item.options}
                data={item.data}
              />
            )
          }

        })
      }
    </div>
  )
}
