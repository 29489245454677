import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { icons } from '../../constants/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ReactDropDown from '../dropdown'
export default function UserProfile({
    id, 
    avatar,
}) {

    const { t } = useTranslation()
    const navigate = useNavigate()
    let [show, setShow] = useState(false)

    let showProfileControl = () => {
        setShow(!show)
    }

    if(id === 0) {
        return <Link to="/login" className="btn btn-main pill">
        <span className="icon-left icon">
          <img src={icons.user} alt="" />
        </span>
        {t("sign.in")}
      </Link>
    }

  return (
    <div className="user-profile">
        <button className="user-profile__button flex-align" onClick={showProfileControl}>
            <span className="user-profile__thumb">
                <img
                    src={avatar}
                    className="cover-img"
                    alt=""
                />
            </span>
        </button>
        <ReactDropDown 
            show={show}
        />

    </div>
  )
}
