
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { GOOD_TYPE_NORMAL } from "../constants";
import ProductBestSellCard from "./product/bestSellCard";
import { gradient, shapes } from "../constants/icons";

const SellingOne = ({
  title,
  description,
  list,
  view_all_items
}) => {

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-right" />
      </button>
    );
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;

    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-left" />
      </button>
    );
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        },
      },
    ],
  };
  return (
    <section className="selling-product padding-y-120 position-relative z-index-1 overflow-hidden">
      <img
        src={gradient.selling_gradient}
        alt=""
        className="bg--gradient"
      />
      <img
        src={shapes.element2}
        alt=""
        className="element one"
      />
      <img
        src={shapes.element1}
        alt=""
        className="element two"
      />
      <img
        src={shapes.curve_pattern1}
        alt=""
        className="position-absolute start-0 top-0 z-index--1"
      />
      <img
        src={shapes.curve_pattern2}
        alt=""
        className="position-absolute end-0 top-0 z-index--1"
      />
      <div className="container container-two">
        <div className="section-heading style-left style-white flx-between max-w-unset gap-4">
          <div>
            <h3 className="section-heading__title">
              {title}
            </h3>
            <p className="section-heading__desc font-18">
              {description}
            </p>
          </div>
          <Link
            to={`/all-product?type=${GOOD_TYPE_NORMAL}`}
            className="btn btn-main btn-lg pill fw-300"
          >
            {view_all_items}
          </Link>
        </div>
        <div className="selling-product-slider">
          <Slider {...settings}>
            
            {
              list && list.length > 0 && list.map(item => {
                return (
                  <ProductBestSellCard key={`good-${item.id}`} good={item} />
                )
              })
            }
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default SellingOne;
