import React from 'react'

export default function Text({
    options, 
    data
}) {
    const align = options?.align;
    const title = options?.title;
    const font_size = options?.title;
    const font_color = options?.font_color

  return (
    <p className="blog-details-content__desc mb-32" 
        style={{
            textIndent: 30
        }}
        >
        {title}
    </p>
  )
}
