import React from 'react'
import Block from './block'
import {
    vs,
    vs2015,
    arta,
  } from "react-syntax-highlighter/dist/cjs/styles/hljs";

export default function Code({
    options,
    data 
}) {

    const code = options.code;

  return (
    <div>
        
      <Block 
        code={code}
        style={vs2015}
        language={"typescript"}
      />
    </div>
  )
}
