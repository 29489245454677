import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { gradient, shapes } from "../constants/icons";
import SocialList from '../components/social/SocialList'
const Contact = ({
  email,
  contact_number,
  description,
  formspree_id
}) => {
  const { t } = useTranslation()
  return (
    <section className="contact padding-t-120 padding-b-60 section-bg position-relative z-index-1 overflow-hidden">
      <img
        src={gradient.banner_two_gradient}
        alt=""
        className="bg--gradient"
      />
      <img
        src={ shapes.pattern_five }
        className="position-absolute end-0 top-0 z-index--1"
        alt=""
      />
      <div className="container container-two">
        <div className="row gy-4">
          <div className="col-lg-5">
            <div className="contact-info">
              <h3 className="contact-info__title">{t("get.in.touch.with.us.today")}</h3>
              <p className="contact-info__desc">
                {description}
              </p>
              <div className="contact-info__item-wrapper flx-between gap-4">
                <div className="contact-info__item">
                  <span className="contact-info__text text-capitalize d-block mb-1">
                    {t("give.us.a.call")}
                  </span>
                  <Link
                    to={`tel:${contact_number}`}
                    className="contact-info__link font-24 fw-500 text-heading hover-text-main"
                  >
                    {contact_number}
                  </Link>
                </div>
                <div className="contact-info__item">
                  <span className="contact-info__text text-capitalize d-block mb-1">
                    {t("give.us.an.email")}
                  </span>
                  <Link
                    to={`tel:${email}`}
                    className="contact-info__link font-24 fw-500 text-heading hover-text-main"
                  >
                    {email}
                  </Link>
                </div>
              </div>
              <div className="mt-24">
                <SocialList />
              </div>
            </div>
          </div>
          <div className="col-lg-7 ps-lg-5">
            <div className="card common-card p-sm-4">
              <div className="card-body">
                <form action={`https://formspree.io/f/${formspree_id}`} method="POST" autoComplete="off">
                  <div className="row gy-4">
                    <div className="col-sm-6 col-xs-6">
                      <label
                        htmlFor="name"
                        className="form-label mb-2 font-18 font-heading fw-600"
                      >
                        {t('full.name')}
                      </label>
                      <input
                        type="text"
                        className="common-input common-input--grayBg border"
                        id="name"
                        name="name"
                        placeholder={t("your.name.here")}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-xs-6">
                      <label
                        htmlFor="email"
                        className="form-label mb-2 font-18 font-heading fw-600"
                      >
                        {t("your.email")}
                      </label>
                      <input
                        type="email"
                        className="common-input common-input--grayBg border"
                        id="email"
                        name="email"
                        placeholder={t("your.email.here")}
                        required
                      />
                    </div>
                    <div className="col-sm-12">
                      <label
                        htmlFor="message"
                        className="form-label mb-2 font-18 font-heading fw-600"
                      >
                        {t("your.message")}
                      </label>
                      <textarea
                        className="common-input common-input--grayBg border"
                        id="message"
                        placeholder={t("write.your.message.here")}
                        defaultValue={""}
                        name="message"
                        required
                      />
                    </div>
                    <div className="col-sm-12">
                      <button className="btn btn-main btn-lg pill w-100" type="submit">
                        {" "}
                        {t("submit")}{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default Contact;