import React, { useState } from "react";
import BannerOne from "../components/BannerOne";
import PopularOne from "../components/PopularOne";
import ArrivalOne from "../components/ArrivalOne";
import FlashDealOne from "../components/FlashDealOne";
import SellingOne from "../components/SellingOne";
import FeaturedAuthor from "../components/FeaturedAuthor";
import PerformanceAuthor from "../components/PerformanceAuthor";
import BlogOne from "../components/blog/BlogOne";
import BecomeSellerOne from "../components/BecomeSellerOne";
import BrandSectionOne from "../components/brand/BrandSectionOne";

import BaseLayout from "../layout/BaseLayout";
import { useEffectOnce } from "../utils/func";
import * as goodsCategoryApi from '../api/goodsCategory'
import * as goodsApi from '../api/goods'
import * as seckillApi from '../api/seckill'
import * as blogApi from '../api/blog'
import { SORT_TYPE } from "../constants";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const HomePage = () => {

  const [baseInfo,setBaseInfo] = useState(null)
  const [goodsCategoryList,setGoodsCategoryList] = useState([])
  const [goodsList,setGoodsList] = useState([])
  const [seckillList,setSeckillList] = useState([])
  const [bestSellList,setBestSellList] = useState([])
  const [topAuthor,setTopAuthor] = useState({
    id: 1,
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?uid=R169531261&ga=GA1.1.1200663390.1729478885&semt=ais_hybrid",
    create_time: 1730778794
  })
  const [topAuthGoodsList,setTopAuthorGoodsList] = useState([])
  const [blogList, setBlogList] = useState([])

  const { t } = useTranslation()

  useEffectOnce(() => {
    load()
  })

  function load(){
    loadCategoryList()
    loadGoodsList()
    loadSeckillList()
    loadBestSellList()
    loadBlogList()
  }

  async function loadCategoryList() {
    const response = await goodsCategoryApi.list()
    if(response && response.code == 0) {
      setGoodsCategoryList(response.result.list)
    }
  }

  async function loadGoodsList(params = {}) {
    
    const payload = Object.assign({ rows: 8, ...params })
    const response = await goodsApi.list( payload )
    if(response && response.code === 0) {
      setGoodsList(response.result.list)
    }
  }

  async function loadSeckillList( params = { }) {
    const response = await seckillApi.list( params )
    if(response && response.code === 0) {
      setSeckillList(response.result.list)
    }
  }

  async function loadBestSellList(){
    const payload = Object.assign({ rows:4, sort_type: SORT_TYPE.sale_high_to_low })
    const response = await goodsApi.list( payload )
    if(response && response.code === 0) {
      setBestSellList(response.result.list)

      loadTopAuthor(response.result.list[0])
    }
  }

  async function loadTopAuthor(bestSellGood)
  {
    const supplier = bestSellGood?.supplier
    // setTopAuthor(supplier)

    const payload = {
      rows: 4, 
      supplier_id: supplier?.id 
    }
    console.log('payload is', payload )
    const response = await goodsApi.list(payload)
    console.log('supplier good is ', response)
    if(response && response.code === 0) {
      setTopAuthorGoodsList(response.result.list)
    }
  }

  async function loadBlogList() {
    const response = await blogApi.list({ rows : 3 })
    if(response && response.code === 0) {
      setBlogList(response.result.list)
    }
  }

  return (
    <section className="change-gradient">
 

    {/* SaleOffer */}
    {/* <SaleOffer /> */}
    

    <BaseLayout
      seo_title={t("home.seo.title")}
      seo_description={t("home.seo.description")}
      seo_keywords={t("home.seo.keywords")}
      onBaseInfo={info => {
        setBaseInfo(info)
      }}
    >
        {/* BannerOne */}
        <BannerOne 
          title={t("banner.title")}
          description={t("banner.description")}
          search_placeholder={t("banner.search.placeholder")}
          customer_amount={t("customers.amount")}
          customer_title={t("customers.title")}
          theme_plugin_amount={t("themes.plugins.amount")}
          theme_plugin_title={t("themes.plugins.title")}
        />

        {/* PopularOne */}
        <PopularOne 
          title={t("popular.categories")}
          list={goodsCategoryList}
          explore_more={t("explore.more")}
        />

        {/* ArrivalOne */}
        <ArrivalOne 
          title={t("new.arrival.products")}
          categoryList={goodsCategoryList}
          goodsList={goodsList}
          onCategoryChange={categoryId => loadGoodsList(categoryId > 0 ?  { category_ids: [categoryId]} : {} )}
          view_all_products={t("view.all.products")}
        />

        {/* FlashDeal */}
        <FlashDealOne 
          title={t("flash.deal.products.title")}
          description={t("flash.deal.products.description")}
          list={seckillList}
          view_all_products={t("view.all.products")}
        />

        {/* SellingOne */}
        <SellingOne 
          title={t("weekly.best.selling.products.title")}
          description={t("weekly.best.selling.products.description")}
          list={bestSellList}
          view_all_items={t("view.all.products")}
        />

        {/* FeaturedAuthor */}
        <FeaturedAuthor 
           title={t("top.featured.author.title")}
           description={t("top.featured.author.description")}
           view_profile={t("author.view_profile")}
           fallow={t("author.fallow")}
           author={topAuthor}
           author_register_from={t("author.register.from",{ time: dayjs(topAuthor.create_time * 1000).format('YYYY-MM')}) }
           list={topAuthGoodsList}
           author_top_featured={t("top.dp.marketer.featured.author")}
        />

        {/* PerformanceAuthor */}
        <PerformanceAuthor 
           title={t("top.performance.title")}
           description={t("top.performance.description")}
           email_subscription={t("email.subscription")}
           email_subscription_amount={"49,100+"}
           total_products={t("total.products")}
           total_products_amount={"48,500+"}
           total_download={t("total.download")}
           total_download_amount={"43,300+"}
           monthly_visitor={t("monthly.visitor")}
           monthly_visitor_amount={"65,500+"}
           get_started={t("get.started")}
        />

        {/* BlogOne */}
        <BlogOne 
          title={t("browse.all.latest.blogs.and.articles")}
          browser_all_articles={t("browse.all.articles")}
          list={blogList}
        />

        {/* BecomeSellerOne */}
        <BecomeSellerOne 
          seller_earn_title={t("seller.earn.title")}
          seller_earn_description={t("seller.earn.description")}
          seller_register={t("seller.register")}
          affiliate_earn_title={t("affiliate.earn.title")}
          affiliate_earn_description={t("affiliate.earn.description")}
          affiliate_register={t("affiliate.register")}
          support_7_24={t("support.24.7")}
          support_message={t("send.us.a.message")}
          email={baseInfo?.email}
        />

        {/* BrandSectionOne */}
        <BrandSectionOne />

    </BaseLayout>


  </section>
  );
};

export default HomePage;
