
import {
    get,
    post
} from '../utils/request'


export const list = async (params = { }) => {
    try {
        const response = await get("/comment/list", params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}

export const add = async (params = { }) => {
    try {
        const response = await post("/comment/add", params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}

export const edit = async (params = { }) => {
    try {
        const response = await post("/comment/edit", params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}