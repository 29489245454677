import React, { useState } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import AllProduct from "../../components/product/AllProduct";
import ResourceOne from "../../components/ResourceOne";
import BrandSectionOne from "../../components/brand/BrandSectionOne";
import BaseLayout from "../../layout/BaseLayout";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "../../utils/func";
import * as goodsCategoryApi from '../../api/goodsCategory'
import * as goodsApi from '../../api/goods'
import { t } from "i18next";

const AllProductPage = () => {

  const [loading,setLoading] = useState(false)
  const [categoryList,setCategoryList] = useState([])

  // 商品总数
  const [total,setTotal] = useState(58000)

  const [goodsResult,setGoodsResult] = useState({
    list: [],
    total_number: 0
  })

  const [queryParameters] = useSearchParams()

  const categoryId = queryParameters.get('category_id')

  useEffectOnce(() => {
    loadGoodsCategory()
    loadGoodsList(categoryId && categoryId > 0 ? { category_ids:[categoryId] } : {})
  })

  async function loadGoodsCategory() {
    const response = await goodsCategoryApi.list({ with_quanaity : 1 })
    if(response && response.code === 0) {
      setCategoryList(response.result.list)
    }
  }

  async function loadGoodsList( params = {}) {
    try {
      setLoading(true)
      const response = await goodsApi.list(Object.assign(params, { rows: 12 }))
      if(response && response.code === 0 ) {
        setGoodsResult(response.result)
      }
    } catch (error) {
      
    } finally {
      setLoading(false)
    }
   
  }

  return (

    <>

      {/* HeaderOne */}
      {/* <HeaderOne /> */}
      <BaseLayout
        seo_title={t("products.seo.title")}
        seo_description={t("products.seo.description")}
        seo_keywords={t("products.seo.keywords")}
        onBaseInfo={info => {}}
      >
            {/* Breadcrumb */}
          <Breadcrumb 
            quantity={total}
          />

          {/* AllProduct */}
          <AllProduct 
            categoryList={categoryList}
            goodsList={goodsResult.list}
            totalNumber={goodsResult.total_number}
            onFilter={params => loadGoodsList(params)}
            loading={loading}
          />

          {/* ResourceOne */}
          <div className="resource-style-two">
            {/* <ResourceOne /> */}
          </div>

          <BrandSectionOne />
      </BaseLayout>
    </>
  );
};

export default AllProductPage;
