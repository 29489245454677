import React from 'react'
import {QRCodeSVG} from 'qrcode.react';
import { SIZES } from '../../constants';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
export default function Alipay({
    logo,
    productName,
    price,
    content,
}) {

    const { i18n } = useTranslation()


    function onPay() {
        let divForm = document.getElementsByTagName('divform')
        if (divForm.length) {
            document.body.removeChild(divForm[0])
        }
        const div=document.createElement('divform');
        div.innerHTML= content ;
        document.body.appendChild(div);
        document.forms[0].setAttribute('target', '_blank') 
        document.forms[0].submit();
    }

    function renderInfo() {
        return (
            <div
                style={{
                    display:'flex',
                    flexDirection: 'column'
                }}
            >
                {/* logo  */}
                <img src={logo} alt='logo' style={{ width: 100 ,height: 30}} />

                {/* product name  */}
                <div
                    style={{
                        display:'flex',
                        marginTop: SIZES.radius,
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                           fontSize: SIZES.h3,

                        }}
                    >{productName}</div>

                    {/* price */}
                    <div>{`${i18n.language == "zh" ? "￥" : "$" } `} {price}</div>
                </div>
                
                
            </div>
        )
    }

    function renderQrcode() {
        return (
            <div
                style={{
                    marginLeft: SIZES.padding,
                    display:'flex',
                    flexDirection:'column'
                }}
            >
                <button className="btn btn-danger btn-lg pill" onClick={() => onPay()} style={{
                    marginTop: SIZES.radius
                }}>
                    {" "}
                    {t("pay")}
                    <span className="icon icon-right line-height-1 font-20">
                    <i className="las la-arrow-right" />
                    </span>
                </button>
            </div>
        )
    }

  return (
    <div 
        style={{
            display: 'flex',
            flexDirection: 'row'
        }}
    >
        { renderInfo()  }

        { renderQrcode() }
    </div>
  )
}
