import React from 'react'
import { icons } from '../../constants/icons'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { t } from 'i18next';
import { message } from 'react-message-popup';

export default function Share({
    text,
}) {
  return (
    <CopyToClipboard text={text} >
        <div className="user-profile">
        <button className="user-profile__button flex-align" onClick={() => {
            
            message.success(t("link.copy.success.and.share"))
        }}>
            <span className="user-profile__thumb" style={{
                display: 'flex',
                alignItems:'center'
            }}>
                <img
                    src={icons.share_icon}
                    className="cover-img"
                    alt=""
                    style={{
                        width: 30,
                        height: 30
                    }}
                />
            </span>
        </button>
    </div>
    </CopyToClipboard>
    
  )
}
