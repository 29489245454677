import React from 'react'
import ProductVerticalCard from '../../product/verticalCard'
export default function GoodsList({
    options,
    data 
}) {
  return (
    <div className="row gy-4 list-grid-wrapper">
        {
          data && data.map((item,index) => {
            return (
              <ProductVerticalCard 
                key={`vcard-${index}`}
                className="col-xl-4 col-sm-6"
                good={item}
              />
            )
          })
        }
      </div>
  )
}
