
import React from 'react'
import { Link } from 'react-router-dom'
import { toBlogDetail } from '../../utils/blog'
import { useTranslation } from 'react-i18next'
import { locale } from '../../utils/time'
export default function RecentBlogCard({
    blog
}) {
    const { i18n } = useTranslation()
  return (
    <div className="latest-blog">
        <div className="latest-blog__thumb">
            <Link to={toBlogDetail(blog.id, blog.is_with_sidebar)}>
                {" "}
                <img
                    src={blog.image}
                    className="cover-img"
                    alt=""
                />
            </Link>
        </div>
        <div className="latest-blog__content">
            <span className="latest-blog__date font-14 mb-2">
                {locale(blog.create_time, i18n.language)}
            </span>
            <h6 className="latest-blog__title fw-500 font-body font-16">
                <Link to={toBlogDetail(blog.id, blog.is_with_sidebar)}>
                    {blog.title}
                </Link>
            </h6>
        </div>
    </div>
  )
}
