import React, { useState } from "react";
import BrandSectionOne from "../components/brand/BrandSectionOne";
import BreadcrumbEight from "../components/breadcrumb/BreadcrumbEight";
import Contact from "../components/Contact";
import NewsletterTwo from "../components/NewsletterTwo";
import BaseLayout from "../layout/BaseLayout";
import SEO from "../components/SEO";
import { t } from "i18next";
const ContactPage = () => {

  const [info,setInfo] = useState(null)
  return (
    <BaseLayout
      onBaseInfo={info => {
        setInfo(info)
      }}
    >
        <SEO 
          title={t("contact.us")}
        />
        {/* BreadcrumbEight */}
        <BreadcrumbEight />

        {/* Contact */}
        <Contact 
          email={info?.email}
          contact_number={info?.contact_number}
          description={info?.description}
          formspree_id={info?.formspree_id}
        />

        {/* NewsletterTwo */}
        {/* <NewsletterTwo /> */}

        {/* BrandSectionOne */}
        <BrandSectionOne />
    </BaseLayout>
  );
};

export default ContactPage;
