

export const en_social_links = [
    {
        id: 1,
        name: "fa-facebook-f",
        url: "https://www.facebook.com"
    },
    {
        id: 2,
        name: "fa-google",
        url: "https://www.google.com"
    },
    {
        id: 3,
        name: "fa-twitter",
        url: "https://www.twitter.com"
    },
];

export const cn_social_links = [
    {
        id: 1,
        name: "fa-weibo",
        url: "https://www.weibo.com"
    },
    {
        id: 2,
        name: "fa-weixin",
        url: "https://www.weixin.com"
    },
    {
        id: 3,
        name: "fa-tiktok",
        url: "https://www.toutiao.com"
    },
];