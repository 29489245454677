import React from 'react'

export default function ImageAds({
  options,
  data
}) {

  return (
    <div className="blog-details-content__thumb mb-32">
      <img src={data[0].img.url} alt="" />
    </div>
  )
}
