import React, { useState } from "react"
import BrandSectionOne from "../../components/brand/BrandSectionOne";
import BlogDetailsSidebar from "../../components/BlogDetailsSidebar";
import BaseLayout from "../../layout/BaseLayout";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "../../utils/func";
import * as blogApi from '../../api/blog'
import * as commentApi from '../../api/comment'
import * as blogCategoryApi from '../../api/blogCategory'
import { REDDIRECT_URL, TOKEN } from "../../constants";
import { useNavigate } from "react-router-dom";
import { getRedirectUrl } from "../../utils";
import { message } from "react-message-popup";
import { useTranslation } from "react-i18next";
import SEO from "../../components/SEO";
const BlogDetailsSidebarPage = () => {

  let params = useParams();
  const id = params.id;
  const { t } = useTranslation();

  const [replyId,setReplyId] = useState(0)
  const [categoryList, setCategoryList] = useState([])

  const [info,setInfo] = useState({
    author: {},
    id: 0,
    title: "",
    description: "",
    image: "",
    category_id: 0,
    body: [],
    tag_ids: [],
    tags: [],
    latest_blog_list: []
  })

  const [comments, setComments] = useState({
    list: [],
    total_number: 0
  })

  const navigate = useNavigate()

  useEffectOnce(() => {
    loadInfo()
    loadCategoryList()
  }, [])

  async function loadInfo(){
    const response = await blogApi.info({ id })
    console.log('blog info is', response)
    if(response && response.code === 0) {
      setInfo(response.result.info)
      loadComments(response.result.info.id)
    }
  }

  async function loadComments(blog_id) {
    const response = await commentApi.list({ blog_id })
    if(response && response.code === 0) {
      setComments(response.result)
    }
  }

  async function loadCategoryList() {
    const response = await blogCategoryApi.list({ with_quantity : 1 })
    console.log('blog category response is--------', response)
    if(response && response.code === 0) {
      setCategoryList(response.result.list)
    }
  }

  async function addComment(comment) {
      // 判断用户有没有登录
      const token = localStorage.getItem(TOKEN)
      console.log('token is', token)
      if(token) {
        console.log("直接提交")
        handleCommit(comment)
      } else {
        const redirectUrl = getRedirectUrl();
        localStorage.setItem(REDDIRECT_URL, redirectUrl);
        navigate("/login")
      }
  }

  async function handleCommit(content) {
    const payload = {
      content,
      blog_id: info?.id,
      good_id: 0,
      pid: replyId,
    }
    const response = await commentApi.add(payload)

    if(response && response.code === 0) {
      message.success(t("success"))
      loadComments(info?.id)
    } else {
      message.warning(t("post.fail"))
    }
  }


  return (
    <BaseLayout>
      {/* BlogDetailsSidebar */}
      <SEO 
        title={info?.title}
        description={info?.description}
        tags={info?.tags?.toString()}
      />

      <BlogDetailsSidebar 
        user_info={info.user_info}
        title={info.title}
        description={info.description}
        body={info.body}
        create_time={info.create_time}
        tags={info?.tags}
        onAddComment={addComment}
        comments={comments}
        onReply={pid => setReplyId(pid)}
        latest_blog_list={info?.latest_blog_list}
        category_list={categoryList}
      />
      {/* BrandSectionOne */}
      <BrandSectionOne />
    </BaseLayout>
  );
};

export default BlogDetailsSidebarPage;
