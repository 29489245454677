import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { gradient, shapes } from "../constants/icons";
import SocialList from '../components/social/SocialList'
import { COLORS, SIZES } from "../constants";
import { getLinkPath } from "../utils";
const FooterOne = ({
  logo,
  description,
  address,
  contact_number,
  email,
  site_name,
  company_name,
  beian,
  data,
  list 
}) => {
  const { t } = useTranslation()

  function renderSubmenu(item,index) {
    return (
      <div key={`menu-${index}`} className="col-xl-2 col-sm-6 col-xs-6">
        <div className="footer-widget">
          
          <h5 className="footer-widget__title text-white">{item?.title}</h5>
          <ul className="footer-lists">
            {
              item?.list && item?.list?.length > 0 && item?.list.map((sub,inx) => {
                const { path,target } = getLinkPath(sub?.link)
                return (
                  <li className="footer-lists__item" key={`sub-${inx}`}>
                    <Link to={path} target={target} className="footer-lists__link">
                      {sub?.title}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    )
  }

  return (
    <>
      <footer className="footer-section">
        <img
          src={shapes.pattern}
          alt=""
          className="bg-pattern"
        />
        <img
          src={shapes.element1}
          alt=""
          className="element one"
        />
        <img
          src={shapes.element2}
          alt=""
          className="element two"
        />
        <img
          src={gradient.footer_gradient}
          alt=""
          className="bg--gradient"
        />
        <div className="container container-two">
          <div className="row gy-5">
            <div className="col-xl-3 col-sm-6">
              <div className="footer-widget">
                <div className="footer-widget__logo">
                  <Link to="/">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <p className="footer-widget__desc">
                  {description}
                </p>
                <p className="footer-widget__desc">
                  {email}
                </p>
                <p className="footer-widget__desc">
                  {contact_number}
                </p>
                <p className="footer-widget__desc">
                  {address}
                </p>
                <SocialList 
                  containerStyle={{
                    marginTop: SIZES.body6
                  }}
                />
                
              </div>
            </div>
            {
              list && list?.length > 0 && list.map((item,index) => {
                return renderSubmenu(item,index)
              })
            }
            
            
          </div>
        </div>
      </footer>
      {/* bottom Footer */}
      <div className="bottom-footer">
        <div className="container container-two">
          <div className="bottom-footer__inner flx-between gap-3">
            <p className="bottom-footer__text font-14">
              {/* {`Copyright © ${new Date().getFullYear()} ${company_name} , All rights reserved.`} */}
              {t("copyright", { year: new Date().getFullYear(),company: company_name })}
              <Link 
                to="https://beian.miit.gov.cn" 
                target="_blank" 
                className="footer-link font-14"
                style={{
                  marginLeft: 10
                }}
                >
                {beian}
              </Link>
            </p>
        
             
            <div className="footer-links">
              <Link to="#" className="footer-link font-14">
                Terms of service
              </Link>
              <Link to="#" className="footer-link font-14">
                Privacy Policy
              </Link>
              <Link to="/contact" className="footer-link font-14">
                cookies
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterOne;
