import React from 'react'

export default function Title({
    data,
    options,
}) {
    const align = options?.align;
    const title = options?.title;
    const font_size = options?.title;
    const font_color = options?.font_color
  return (
    <h5 
      className="blog-details-content__title mb-3" 
      style={{
        color: font_color,
        fontSize: font_size
    }}>
       {title}
    </h5>
  )
}
