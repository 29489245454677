import React, { useEffect, useState } from "react";
import BreadcrumbTwo from '../../components/breadcrumb/BreadcrumbTwo'
import ProductDetails from "../../components/product-detail";
import BrandSectionOne from "../../components/brand/BrandSectionOne";
import BaseLayout from "../../layout/BaseLayout";
import * as goodsApi from '../../api/goods'
import * as goodsReviewApi from '../../api/goodsReview'
import * as seckillApi from '../../api/seckill'
import * as seckillBuyApi from '../../api/seckillBuy'
import * as paynameApi from '../../api/payname'
import * as buyApi from '../../api/buy'
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isMobilePhone, useEffectOnce } from "../../utils/func";

import { getRedirectUrl } from "../../utils";

import { GOOD_TYPE_NORMAL, GOOD_TYPE_SECKILL, REDDIRECT_URL, TOKEN } from "../../constants";

import SEO from "../../components/SEO";
import { message } from "react-message-popup";
import SettleDialog from "../../components/settle/dialog";
import SettlePaypal from "../../components/settle/paypal";

const ProductDetailsPage = () => {

    const navigate = useNavigate()
    let params = useParams();
    const id = params.id;

    const [queryParameters] = useSearchParams()
    
    const [type, setType] = useState(GOOD_TYPE_NORMAL)
    const [title,setTitle] = useState("")
    const [description,setDescription] = useState("")
    const [categoryLabel,setCategoryLabel] = useState("")
    const [img,setImg] = useState("")
    const [body,setBody] = useState([])
    const [previewUrl,setPreviewUrl] = useState("")
    const [features,setFeatures] = useState([])
    const [tags,setTags] = useState([])
    const [skus,setSkus] = useState([])
    const [seckillGoods,setSeckillGoods] = useState([])
    const [supplier,setSupplier] = useState({
      id: 0,
      title: ""
    })
    const [saleNum,setSaleNum] = useState(0)
    const [fileSize,setFileSize] = useState('')
    const [compatibilities,setCompatibilities] = useState([])
    const [includeFiles,setIncludeFiles] = useState([])
    const [techframeworks,setTechframeworks] = useState([])
    const [moreGoodsList,setMoreGoodsList] = useState([])
    const [createTime,setCreateTime] = useState(0)
    const [updateTime,setUpdateTime] = useState(0)
    const [reviewResult,setReviewResult] = useState({
      list: [],
      total_number: 0
    })
    const [isCheckouting,setIsCheckouting] = useState(false) 
    const [paySn,setPaysn] = useState("")
    const [paypalClientId,setPaypalClientId] = useState("")
    const [orderSn,setOrderSn] = useState("")
    const [showSettle,setShowSettle] = useState(false)
    const [paynameList,setPaynameList] = useState([])
    const [showPaypal,setShowPayal] = useState(false)
    const [commentResult,setCommentResult] = useState({
      list: [{
        avatar: "https://tse4-mm.cn.bing.net/th/id/OIP-C._8Kty4btP3aJuyTfZTaR0wHaHk?w=202&h=206&c=7&r=0&o=5&dpr=2&pid=1.7",
        nickname: "Stocker",
        create_time: 1726112562,
        message: "If the code can contain frontend and backend ,well be better",
        child:[
          {
            avatar: "https://tse1-mm.cn.bing.net/th/id/OIP-C.mEma0ZcipymPAHIYoIuFiAHaJa?w=162&h=206&c=7&r=0&o=5&dpr=2&pid=1.7",
            nickname: "Kevin Ashley",
            create_time: 1726110562,
            message: "Yes, the template can be developed further"
          }
        ]
      },

    ],
      total_number: 2
    })

    // useEffect(() => { init() },[])

    useEffectOnce(() => {
        
        init()
    },[])

    function init() {
      const type = queryParameters.get("type")
      if( type ) {
        setType(type)
      }
      load(type)
      loadPaynames();
    }
    
    function load(type) {
        console.log('79 load type is',type)
        if(type === GOOD_TYPE_SECKILL ){
            loadSeckillInfo()
        } else {
            loadGoodInfo()
        }
    }

    async function loadGoodInfo() {
        const param = {
            sn:id 
        }
        const response = await goodsApi.info(param)
        if(response && response?.code === 0) {
            const info = response.result.info;
            setValue(info)
        }
    }

    async function loadSeckillInfo() {
        console.log('load seckill info called...')
        const payload = {
          seckill_id: id 
        }
        const response = await seckillApi.info(payload)
        console.log('seckill response is', response)
        if(response && response.code === 0) {
          const info = response.result.info;
          setValue(info)
        }
    }

    function setValue( info ) {
         
          setTitle(info?.title)
          setDescription(info?.description)
          setCategoryLabel(info?.category_label)
          setImg(info?.img)
          setBody(info?.body)
          setFeatures(info?.features)
          setTags(info?.tags)
          setPreviewUrl(info?.url?.preview_url)
          setSupplier(info?.supplier)
          setSaleNum(info?.sale_num)
          setSkus(info?.skus)
          setCompatibilities(info?.compatibilities)
          setIncludeFiles(info?.includefiles)
          setTechframeworks(info?.techframeworks)
          setFileSize(info?.file_size)
          setCreateTime(info?.create_time)
          setUpdateTime(info?.update_time)

          loadReviews(info?.id)
          loadMoreGoods(info?.supplier?.id)

          setSeckillGoods(info?.seckill_goods)
          
          setPaypalClientId(info?.paypal_client_id)
    }

    async function loadMoreGoods(supplierId)
    {
      const response = await goodsApi.list({ supplier_id: supplierId })

      if(response && response.code === 0) {
        setMoreGoodsList(response.result.list)
      }
    }

    async function loadReviews(goods_id){
      const response = await goodsReviewApi.list({ goods_id })
      if(response && response.code === 0) {
        setReviewResult(response.result)
      }
    }

    async function checkout(goodsSkuId,goodsId, seckillId) {
        
      const token = localStorage.getItem(TOKEN)
    
      const redirectUrl = getRedirectUrl();

      if( !token || token == null ) {
          // login account 
          localStorage.setItem(REDDIRECT_URL,redirectUrl)
          navigate("/login")
      } else {
          console.log('create order ...')
          createOrder(goodsSkuId,goodsId, seckillId)
      }
  }

  async function createOrder(goodsSkuId,goodsId, seckillId) {
      try {
        setIsCheckouting(true)
        if(type === GOOD_TYPE_NORMAL) {
          const param = {
            goods_sku_id: goodsSkuId,
            quantity: 1
          }
          const response = await buyApi.createOrderByGoodsSkuId(param)
          console.log('155 create order by goods sku id response',response)
          if(response && response.code === 0) {
            const result = response.result;
            const pay_sn = result.pay_sn;
            const order_id = result.order_id;
            const order_sn = result.sn
  
            // 跳转到支付页面
            readyToSettle(order_sn,pay_sn)
          }
        } 
        else if(type === GOOD_TYPE_SECKILL) {
            console.log('193 seckill create order ',seckillId)
            const payload = {
                goods_sku_id: goodsSkuId,
                seckill_id: seckillId,
                goods_id:goodsId,
                goods_number: 1,
            }
  
             try {
                const response = await seckillBuyApi.createOrder(payload);
                console.log('seckill buy create order response',response)
                if(response && response.code === 0) {
                  const result = response.result;
                  const pay_sn = result.pay_sn;
                  const order_id = result.order_id;
                  const order_sn = result.order_sn;
                  
                  // 跳转到支付页面
                  readyToSettle(order_sn, pay_sn)
                }
             } catch (error) {
              
             }
        }
      } catch (error) {
        
      }
      finally {
        setIsCheckouting(false)
      }
  }

  async function loadPaynames() {
    const response = await paynameApi.list()
    if(response && response.code === 0 ) {
      setPaynameList(response.result.list)
    }
  }

  function readyToSettle(order_sn,pay_sn) {
    console.log('readyToSettle------',order_sn, pay_sn)
    setOrderSn(order_sn)
    setPaysn(pay_sn)
    if(isMobilePhone()) {
      setShowSettle(true)
    } else {
      navigate(`/payment/${pay_sn}`)
    }
    // setShowSettle(true)
  }

  async function toSettle(name) {
      console.log('toSettle', name)
      if(name === "alipay" || name === "wechat") {
        const param = {
          order_type: "goods_buy",
          pay_sn:paySn,
          payment_code: name,
          payment_channel:name,
          is_refresh_code: false,
          is_mobile:  true, // isMobilePhone()
      }
      console.log("toSettle param ", param)
      const response = await buyApi.pay(param);
      console.log('settle response------------------',response)
      if(response && response.code === 0) {
        const content  = response.result.content;
        if(name === "alipay") {
          let divForm = document.getElementsByTagName('divform')
          if (divForm.length) {
              document.body.removeChild(divForm[0])
          }
          const div=document.createElement('divform');
          div.innerHTML= content ;
          document.body.appendChild(div);
          // document.forms[0].setAttribute('target', '_blank') 
          document.forms[0].submit();
        } else if(name === "wechat") {
          window.open(content)
        }
      }
      console.log("280 -- buyapi pay response ", response)
    } else if(name === "paypal") {
      setShowSettle(false)
      setShowPayal(true)
    }
  }

  return (
      <BaseLayout
        seo_title={title}
        seo_description={description}
        seo_keywords={tags && tags.toString()}
      >

        <BreadcrumbTwo 
            title={title}
            categoryLabel={categoryLabel}
            supplier={supplier}
            saleNum={saleNum}
            reviewRate={5.0}
            reviewTotal={reviewResult.total_number}
            commentTotal={commentResult.total_number}
          />

        <ProductDetails
            type={type}
            reviewList={reviewResult.list}
            previewUrl={previewUrl}
            img={img}
            body={body}
            description={description}
            features={features}
            tags={tags}
            supplier={supplier}
            moreGoodsList={moreGoodsList}
            commentTotal={commentResult.total_number}
            commentList={commentResult.list}
            skus={skus}
            seckillGoods={seckillGoods}
            compatibilities={compatibilities}
            includefiles={includeFiles}
            techframeworks={techframeworks}
            fileSize={fileSize}
            checkout={checkout}
            isCheckouting={isCheckouting}
            categoryLabel={categoryLabel}
            createTime={createTime}
            updateTime={updateTime}
          />

         {/* BrandSectionOne */}
         <BrandSectionOne />

         {
            showSettle && <SettleDialog 
              paynameList={paynameList}
              visible={showSettle}
              onClose={() => setShowSettle(false) }
              onClick={toSettle}
            />
         }

         { 
            showPaypal && <SettlePaypal 
              pay_sn={paySn}
              paypal_client_id={paypalClientId}
              visible={showPaypal}
              onClose={() => {setShowPayal(false)}}
              onNavigate={() => navigate(`/customer-order-detail/${orderSn}`) }
            />
         }
      </BaseLayout>)
};

export default ProductDetailsPage;
