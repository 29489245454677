import { useTranslation } from "react-i18next";
import { gradient, icons, shapes } from "../../constants/icons";

const Breadcrumb = ({
  quantity
}) => {
  const { t } = useTranslation()

  return (
    <section className="breadcrumb breadcrumb-one padding-y-60 section-bg position-relative z-index-1 overflow-hidden">
     <img
        src={gradient.breadcrumb_gradient_bg}
        alt=""
        className="bg--gradient"
      />
       <img
        src={shapes.element_moon3}
        alt=""
        className="element one"
      />
      
      <img
        src={shapes.element_moon1}
        alt=""
        className="element three"
      />
      <div className="container container-two">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="breadcrumb-one-content">
              <h3 className="breadcrumb-one-content__title text-center mb-3 text-capitalize">
                {quantity}+ {t("products.available.for.purchase")}
              </h3>
              <p className="breadcrumb-one-content__desc text-center text-black-three">
              {t("products.available.for.purchase.description")}
              </p>
              <form action="#" className="search-box">
                <input
                  type="text"
                  className="common-input common-input--lg pill shadow-sm"
                  placeholder={t("search.theme.plugins.and.more")}
                />
                <button
                  type="submit"
                  className="btn btn-main btn-icon icon border-0"
                >
                  <img src={icons.search} alt="" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
