import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { locale } from '../../utils/time';
import { toBlogDetail } from '../../utils/blog';

export default function BlogHorizontalCard({
    item
}) {
    
    const { t,i18n } = useTranslation();

  return (
    <div className="article-item">
        <div className="article-item__inner d-flex position-relative">
        <div className="article-item__center d-flex align-items-center">
            <div className="article-item__content">
            <div className="article-item__top flx-align">
                {
                    item?.tags && item?.tags.length > 0 && item?.tags.map((tag,index) => {
                        return (
                            <Link to="/blogs" key={`tag-${index}`} className="article-item__tag font-14">
                                {tag}
                            </Link>
                        )
                    })
                }
                
                <span className="text-heading font-16 fw-500">
                {/* June 24, 2024 */}
                {locale(item?.create_time, i18n.language)}
                </span>
            </div>
            <h4 className="article-item__title mb-3">
                <Link to={toBlogDetail(item.id, item.is_with_sidebar)} className="link">
                {" "}
                {item?.title}
                </Link>
            </h4>
            <p className="article-item__desc">

                {item?.description}
            </p>
            </div>
            <div className="article-item__thumb">
            <img src={item?.image} alt="" />
            </div>
        </div>
        </div>
        <div className="article-item__end flex-shrink-0">
        <Link to={toBlogDetail(item.id, item.is_with_sidebar)} target='__target' className="btn-simple">
            {t("read.more")}{" "}
            <span className="icon font-26">
            <i className="las la-arrow-right" />
            </span>{" "}
        </Link>
        </div>
    </div>
  )
}
