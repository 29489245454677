

import dayjs from 'dayjs'
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { locale } from '../../utils/time'
import { toBlogDetail } from '../../utils/blog'
function BlogCard({
  blog
}) {
  const { t } = useTranslation()


  return (
    <div className="col-lg-4 col-sm-6">
            <div className="post-item">
              <div className="post-item__thumb">
                <Link to={toBlogDetail(blog.id, blog.is_with_sidebar)} className="link">
                  <img
                    src={blog.image}
                    className="cover-img"
                    alt=""
                  />
                </Link>
              </div>
              <div className="post-item__content">
                <div className="post-item__top flx-align">
                  <Link
                    to={`/blogs?category_id=${blog.category_id}`}
                    className="post-item__tag pill font-14 text-heading fw-500 hover-text-main"
                  >
                    {blog.category_title}
                  </Link>
                  <div className="post-item__date font-14 flx-align gap-2 font-14 text-heading fw-500">
                    <span className="icon">
                      <img
                        src="assets/images/icons/calendar.svg"
                        alt=""
                        className="white-version"
                      />
                      <img
                        src="assets/images/icons/calendar-white.svg"
                        alt=""
                        className="dark-version"
                      />
                    </span>
                    <span className="text">{locale(blog.create_time)}</span>
                  </div>
                </div>
                <h5 className="post-item__title">
                  <Link to={toBlogDetail(blog.id, blog.is_with_sidebar)} className="link">
                    {blog.title}
                  </Link>
                </h5>
                <Link
                  to={toBlogDetail(blog.id, blog.is_with_sidebar)}
                  className="btn btn-outline-light pill fw-600"
                >
                  {t("read.more")}
                </Link>
              </div>
            </div>
          </div>
  )
}

export default BlogCard
