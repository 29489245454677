import React from 'react';

import { DISTRIBUTOR_USER_ID, GOOD_TYPE_NORMAL, GOOD_TYPE_SECKILL } from "../constants";


export const getGoodDetailUrl = (sn, seckillId) => {

    let url = "/product-details"
    if(sn) {
        url = `${url}/${sn}?type=${GOOD_TYPE_NORMAL}`
    } else {
        url = `${url}/${seckillId}?type=${GOOD_TYPE_SECKILL}`
    }
    
    return url;
}

export const getRedirectUrl = () => {
    const href = window.location.href;
    const pathname = window.location.pathname
    const redirectUrl = href.substring(href.indexOf(pathname))
 
    return redirectUrl
}

export const getShareUrl = () => {
    
    const distributor_user_id = localStorage.getItem(DISTRIBUTOR_USER_ID)
    const href = window.location.href;
    const array = href.split("?")

    let shareUrl = `${href}?distributor_user_id=${distributor_user_id}`
    if(array.length > 1) {
        shareUrl = `${array[0]}?${array[1]}&distributor_user_id=${distributor_user_id}`
    }

    return shareUrl;
}

export const getLinkPath = (link) => {
  
    const { action, param } = link;
    let path = '/'
    let target = ''
    switch(action) {
        case 'portal': //首页
            path = '/';
            break;
        case 'page': //自定义专题页面
            path = `/page/${param.id}`
            break;    
        case 'goods_category':
            path = `/goods`
            break;    
        case 'goods': // 商品详情
            path = `/goods`
            break; 
        case 'good': // 商品详情
            path = `/good/${param.id}`
            break;      
        case 'url': //自定义链接
            target = "__blank"
            path = param?.url;
            break;      
        case 'services': // 所有服务
            path = `/services`;
          break;    
        case 'service': // 服务详情
           path = `/service/${param.id}`
          break;   
        case 'blogs': // 所有博客
            path = `/blogs`;
          break;    
        case 'blog': // 博客详情
           path = `/blog-details/${param.id}`
          break;  
        case 'aboutus': // 关于我们
            path = `/aboutus`
            break;       
        case 'contactus': // 联系我们
            path = `/contactus`
            break;       
        case 'ourteam': // 我们的团队
           path = `/ourteam`
           break;   
        case 'pricing': // 价格
           path = `/priceing`
           break; 
    }
    
    return {
        path,
        target
    } ;
}