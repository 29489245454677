
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { icons, shapes } from "../constants/icons";

const PerformanceAuthor = ({
  title,
  description,
  email_subscription,
  email_subscription_amount,
  total_products,
  total_products_amount,
  total_download,
  total_download_amount,
  monthly_visitor,
  monthly_visitor_amount,
  get_started
}) => {
  const textRef = useRef(null);

  useEffect(() => {
    const text = textRef.current;

    if (text) {
      text.innerHTML = text.innerText
        .split("")
        .map(
          (char, i) => `<span style="transform:rotate(${i * 11.5}deg)">${char}</span>`
        )
        .join("");
    }
  }, []);
  return (
    <section className="top-performance overflow-hidden padding-y-120 position-relative z-index-1">
      <img
        src={shapes.spider_net}
        alt=""
        className="spider-net position-absolute top-0 end-0 z-index--1 white-version"
      />
      <img
        src={shapes.spider_net_white}
        alt=""
        className="spider-net position-absolute top-0 end-0 z-index--1 dark-version"
      />
      <img
        src={shapes.curve_pattern4}
        alt=""
        className="position-absolute top-0 start-0 z-index--1"
      />
      <img
        src={shapes.element2}
        alt=""
        className="element two"
      />
      <div className="container container-two">
        <div className="row gy-4 align-items-center flex-wrap-reverse">
          <div className="col-lg-7 pe-lg-5">
            <div className="position-relative">
              <div className="circle style-two static-circle">
                <div className="circle__badge">
                  <img src={icons.featured_badge} alt="" />
                </div>
                <div className="circle__desc circle__text">
                  <p ref={textRef}>{title}</p>
                </div>
              </div>
              <div className="performance-content">
                <div className="performance-content__item">
                  <span className="performance-content__text font-18">
                    {email_subscription}
                  </span>
                  <h4 className="performance-content__count">{email_subscription_amount}</h4>
                </div>
                <div className="performance-content__item">
                  <span className="performance-content__text font-18">
                    {total_products}
                  </span>
                  <h4 className="performance-content__count">{total_products_amount}</h4>
                </div>
                <div className="performance-content__item">
                  <span className="performance-content__text font-18">
                    {total_download}
                  </span>
                  <h4 className="performance-content__count">{total_download_amount}</h4>
                </div>
                <div className="performance-content__item">
                  <span className="performance-content__text font-18">
                    {monthly_visitor}
                  </span>
                  <h4 className="performance-content__count">{monthly_visitor_amount}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="section-content">
              <div className="section-heading style-left">
                <h3 className="section-heading__title">{title}</h3>
                <p className="section-heading__desc font-18 w-sm">
                  {description}
                </p>
              </div>
              <Link
                to="/register"
                className="btn btn-main btn-lg pill fw-300"
              >
                {get_started}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerformanceAuthor;
