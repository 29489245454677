import * as React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import { message } from 'react-message-popup'
import { useTranslation } from "react-i18next";
function CopyButton({ code }) {

  const { t } = useTranslation()

  return (
    <CopyToClipboard text={code} onCopy={() => message.success(t("copied"))}>
        <div>
          <FaRegCopy />
        </div>
    </CopyToClipboard>
  );
}

export default CopyButton;
