import React, { useState } from "react";
import BreadcrumbSeven from "../../components/breadcrumb/BreadcrumbSeven";
import BrandSectionOne from "../../components/brand/BrandSectionOne";
import BaseLayout from "../../layout/BaseLayout";
import AllBlogList from "../../components/blog/AllBlogList";
import { useEffectOnce } from "../../utils/func";
import * as blogApi from '../../api/blog'
import { useSearchParams } from "react-router-dom";
const BlogPage = () => {
  const [baseInfo,setBaseInfo] = useState(null)

  const [blogs,setBlogs] = useState({
    list: [],
    total_number: 0
  })

  
  const [queryParameters] = useSearchParams()

  const categoryId = queryParameters.get('category_id')
  const tag = queryParameters.get("tag");

  useEffectOnce(() => {

    let payload = {
      category_id: categoryId,
      tag
    }
    loadBlogList( payload )
  }, [categoryId,tag])

  async function loadBlogList(params = {}) {
    const response = await blogApi.list(params)
    console.log("22 blog response is", response)
    if(response && response.code === 0) {
      setBlogs(response.result)
    }
  }

  return (
     <BaseLayout
        onBaseInfo={info => {
          setBaseInfo(info)
        }}
     >

          {/* BreadcrumbSeven */}
          <BreadcrumbSeven />

          {/* Blog */}
          <AllBlogList 
            list={blogs.list}
            total_number={blogs.total_number}
            onFilter={params => loadBlogList(params)}
          />

          {/* BrandSectionOne */}
          <BrandSectionOne />
     </BaseLayout>
  );
};

export default BlogPage;
