import React, { useState } from "react";
import BrandSectionOne from "../../components/brand/BrandSectionOne";
import OrderDetail from "../../components/OrderDetail";
import BaseLayout from "../../layout/BaseLayout";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "../../utils/func";
import * as orderApi from '../../api/order'
const OrderPage = () => {

  let params = useParams();
  const sn = params.sn;
  console.log('order page sn',sn)

  const [info,setInfo] = useState(null)

  useEffectOnce(() => {

    loadInfo()
  })

  async function loadInfo(){
    const response = await orderApi.info({ sn })
    console.log('order here info is', response)
    if(response && response.code === 0) {
      setInfo(response.result.info)
    }
  }

  return (

    <>
      <BaseLayout>
        
          {/* OrderThankYou */}
          <OrderDetail 
            sn={sn}
            status={info?.state_desc}
            paymentMethod={info?.payment_code_desc}
            createTime={info?.create_time}
            amount={info?.amount}
            orderGoods={info?.extend_order_goods}
          />
          
          {/* BrandSectionOne */}
          <BrandSectionOne />

      </BaseLayout>

    </>
  );
};

export default OrderPage;
