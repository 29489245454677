import React from "react";
import BrandSectionOne from "../../components/brand/BrandSectionOne";
import BreadcrumbFive from "../../components/breadcrumb/BreadcrumbFive";
import CartPersonal from "../../components/CartPersonal";
import BaseLayout from "../../layout/BaseLayout";


const CartPersonalPage = () => {

  return (
      <BaseLayout>
        {/* BreadcrumbFive */}
        <BreadcrumbFive />

        {/* CartPersonal */}
        <CartPersonal />

        {/* BrandSectionOne */}
        <BrandSectionOne />
      </BaseLayout>
  );
};

export default CartPersonalPage;
