import { Link } from "react-router-dom";
import VerticalCard from "./VeritcalCard";
import { shapes } from "../../constants/icons";

const BlogOne = ({
  title,
  browser_all_articles,
  list
}) => {
  return (
    <section className="blog padding-y-120 section-bg position-relative z-index-1 overflow-hidden">
      <img
        src={shapes.curve_pattern5}
        className="position-absolute end-0 top-0 z-index--1"
        alt=""
      />
      <div className="container container-two">
        <div className="section-heading style-left style-flex flx-between align-items-end gap-3">
          <div className="section-heading__inner">
            <h3 className="section-heading__title">
              {title}
            </h3>
          </div>
          <Link to="/blogs" className="btn btn-main btn-lg pill">
            {browser_all_articles}
          </Link>
        </div>
        <div className="row gy-4">
          {
            list && list?.length > 0 && list.map((item,index) => {
              return (
                <VerticalCard 
                  key={`blogcard-${index}`}
                  blog={item}
                />
              )
            })
          }
        </div>
      </div>
    </section>
  );
};

export default BlogOne;
