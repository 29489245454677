
import {
    get
} from '../utils/request'

// Axios Get Call - Get all jokes categories.
export const baseInfo = async () => {
    try {
        const response = await get("/footer/baseinfo");
        return response.data;
    }
    catch(error){
        return error.response;
    }
}

export const treelist = async () => {
    try {
        const response = await get("/footer/treelist");
        return response.data;
    }
    catch(error){
        return error.response;
    }
}