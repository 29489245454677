
import { Link } from "react-router-dom";
import { gradient, icons } from "../../constants/icons";
import { t } from "i18next";
import SocialList from '../../components/social/SocialList'

const BreadcrumbTwo = ({
    title,
    categoryLabel,
    supplier,
    saleNum,
    reviewRate,
    reviewTotal,
    commentTotal
}) => {

    return (
        <section className="breadcrumb border-bottom p-0 d-block section-bg position-relative z-index-1" >
            <div className="breadcrumb-two">
                <img
                    src={gradient.breadcrumb_gradient_bg}
                    alt=""
                    className="bg--gradient"
                />
                <div className="container container-two">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="breadcrumb-two-content">
                                <ul className="breadcrumb-list flx-align gap-2 mb-2">
                                    <li className="breadcrumb-list__item font-14 text-body">
                                        <Link
                                            to="/"
                                            className="breadcrumb-list__link text-body hover-text-main"
                                        >
                                            {t("home")}
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-list__item font-14 text-body">
                                        <span className="breadcrumb-list__icon font-10">
                                            <i className="fas fa-chevron-right" />
                                        </span>
                                    </li>
                                    <li className="breadcrumb-list__item font-14 text-body">
                                        <Link
                                            to="/all-product"
                                            className="breadcrumb-list__link text-body hover-text-main"
                                        >
                                            {t("products")}
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-list__item font-14 text-body">
                                        <span className="breadcrumb-list__icon font-10">
                                            <i className="fas fa-chevron-right" />
                                        </span>
                                    </li>
                                    <li className="breadcrumb-list__item font-14 text-body">
                                        <span className="breadcrumb-list__text">{categoryLabel}</span>
                                    </li>
                                </ul>
                                <h3 className="breadcrumb-two-content__title mb-3 text-capitalize">
                                    {title}
                                </h3>
                                <div className="breadcrumb-content flx-align gap-3">
                                    <div className="breadcrumb-content__item text-heading fw-500 flx-align gap-2">
                                        <span className="text">
                                            {t("by")}{": "}
                                            <Link to={`/profile/${supplier?.id}`} className="link text-main fw-600">
                                                {supplier?.title}
                                            </Link>{" "}
                                        </span>
                                    </div>
                                    <div className="breadcrumb-content__item text-heading fw-500 flx-align gap-2">
                                        <span className="icon">
                                            <img
                                                src={icons.cart_icon}
                                                alt=""
                                                className="white-version"
                                            />
                                            <img
                                                src={icons.cart_white}
                                                alt=""
                                                className="dark-version w-20"
                                            />
                                        </span>
                                        <span className="text">{saleNum} {t("sales")}</span>
                                    </div>
                                    <div className="breadcrumb-content__item text-heading fw-500 flx-align gap-2">
                                        <span className="icon">
                                            <img
                                                src="assets/images/icons/check-icon.svg"
                                                alt=""
                                                className="white-version"
                                            />
                                            <img
                                                src="assets/images/icons/check-icon-white.svg"
                                                alt=""
                                                className="dark-version"
                                            />
                                        </span>
                                        <span className="text">{t("recently.updated")}</span>
                                    </div>
                                    <div className="breadcrumb-content__item text-heading fw-500 flx-align gap-2">
                                        <span className="icon">
                                            <img
                                                src="assets/images/icons/check-icon.svg"
                                                alt=""
                                                className="white-version"
                                            />
                                            <img
                                                src="assets/images/icons/check-icon-white.svg"
                                                alt=""
                                                className="dark-version"
                                            />
                                        </span>
                                        {/* <span className="text">Well Documented</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container container-two">
                <div className="breadcrumb-tab flx-wrap align-items-start gap-lg-4 gap-2">
                    <ul className="nav tab-bordered nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-product-details-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-product-details"
                                type="button"
                                role="tab"
                                aria-controls="pills-product-details"
                                aria-selected="true"
                            >
                                {t("product.details")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-rating-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-rating"
                                type="button"
                                role="tab"
                                aria-controls="pills-rating"
                                aria-selected="false"
                            >
                                <span className="d-flex align-items-center gap-1">
                                    <span className="star-rating">
                                        <span className="star-rating__item font-11">
                                            <i className="fas fa-star" />
                                        </span>
                                        <span className="star-rating__item font-11">
                                            <i className="fas fa-star" />
                                        </span>
                                        <span className="star-rating__item font-11">
                                            <i className="fas fa-star" />
                                        </span>
                                        <span className="star-rating__item font-11">
                                            <i className="fas fa-star" />
                                        </span>
                                        <span className="star-rating__item font-11">
                                            <i className="fas fa-star" />
                                        </span>
                                    </span>
                                    <span className="star-rating__text text-body">{reviewRate}</span>
                                    <span className="star-rating__text text-body"> ({reviewTotal})</span>
                                </span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-comments-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-comments"
                                type="button"
                                role="tab"
                                aria-controls="pills-comments"
                                aria-selected="false"
                            >
                                {t("comments")} ({commentTotal})
                            </button>
                        </li>
                    </ul>
                    <div className="social-share">
                        <button type="button" className="social-share__button" >
                            <img src={icons.share_icon} alt="" />
                        </button>
                        <div className="social-share__icons">
                            <SocialList />
                        </div>

                    </div>
                </div>
            </div>
        </section>

    );
}

export default BreadcrumbTwo;