import HeaderOne from "../components/HeaderOne";
import FooterOne from "../components/FooterOne";
import { useEffectOnce } from "../utils/func";
import { useState } from "react";
import Preloader from "../helper/Preloader";
import * as baseApi from '../api/base'
import * as footerApi from '../api/footer'
import * as userApi from '../api/user'
import SEO from "../components/SEO";
import { useTranslation } from "react-i18next";
import { DISTRIBUTOR_USER_ID } from "../constants";
import { getShareUrl } from "../utils";
import { useSearchParams } from "react-router-dom";
import { message } from "react-message-popup";



const BaseLayout = ({ seo_title, seo_description, seo_keywords, children,onBaseInfo }) => {

    const [queryParameters] = useSearchParams()
    
    const distributor_user_id = queryParameters.get("distributor_user_id")
    
    console.log("24 baselayout distributor_user_id", distributor_user_id)


    const { t } = useTranslation()
    const [base,setBase] = useState({
        company_address: "",
        contact_number: "",
        description: "",
        domain: "",
        email: "",
        favicon:"",
        formspree_id: "",
        keywords:"",
        logo: "",
        map_url:"",
        site_name:""
    })

    const [footerInfo,setFooterInfo] = useState({
        company_name: "",
        beian: "",
        description: "",
        data: [],
        menu: []
    })

    const [footerList,setFooterList] = useState([])

    const [userInfo,setUserInfo] = useState({
        id: 0,
        email: "",
        nickname: "",
        avatar: ""
    })

    let [show, setShow] = useState(false)
    let showProfileControl = () => {
        setShow(!show)
    }

    useEffectOnce(() => {
        loadBaseInfo()
        loadFooterInfo()
        loadUserInfo()
        loadFooterList()

        if(distributor_user_id && distributor_user_id.length > 0) {
            message.success(distributor_user_id)
            localStorage.setItem(DISTRIBUTOR_USER_ID, distributor_user_id)
        }
    })

    async function loadBaseInfo(){
       const response = await baseApi.info();
        if(response && response.code == 0) {
            const info = response.result.info
            setBase(info)
            if( onBaseInfo && typeof onBaseInfo === 'function')
            {
                onBaseInfo(info)
            }
        }
    }

    async function loadFooterInfo() {
        const response = await footerApi.baseInfo()
        if(response && response.code === 0) {
            setFooterInfo(response.result.info)
        }
    }

    async function loadFooterList() {
        const response = await footerApi.treelist()
        if(response && response.code === 0) {
            // console.log("footer tree list is--------", response.result.list)
            setFooterList(response.result.list)
        }
    }

    async function loadUserInfo() {
        const response = await userApi.info()
     
        if(response && response.code === 0) {
            const info = response?.result.info;
            console.log("base layout user layout info", info)
            setUserInfo({
                id: info.id,
                email: info.email,
                avatar: info?.profile.avatar,
                nickname: info?.profile.nickname
            })

           
        }
    }

    return <div>
    
        {/* Preloader */}
        <Preloader />

        <SEO 
            title={seo_title}
            description={seo_description}
            keywords={seo_keywords}
        />
        
         {/* HeaderOne */}
        <HeaderOne 
            logo={base.logo}
            userInfo={userInfo}
            shareText={getShareUrl()}
        />

        {children}
           
        {/* FooterOne */}
        <FooterOne 
            logo={base.logo}
            description={base.description}
            email={base.email}
            address={base.company_address}
            site_name={base.site_name}
            contact_number={base.contact_number}
            company_name={footerInfo.company_name}
            beian={footerInfo.beian}
            data={footerInfo.data}
            list={footerList}
        />
    </div>
}

export default BaseLayout