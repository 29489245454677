import { icons, thumbs } from "./icons"

export const TOKEN = "token"
export const REDDIRECT_URL = "redirect_url"
export const DISTRIBUTOR_USER_ID = "distributor_user_id"

export const GOOD_TYPE_NORMAL = "normal"
export const GOOD_TYPE_SECKILL = "seckill"

export const LANGUAGES = [
    { label: "中文", code: "zh" },
    // { label: "Spanish", code: "es" },
    { label: "English", code: "en" },
    // { label: "Italian", code: "it" },
    
];

export const DEFAULT_LANGUAGE = LANGUAGES[0].code

export const tech_list = [
    {
        id: 1,
        title: "WordPress",
        icon: thumbs.tech_icon1
    },
    {
        id: 2,
        title: "Laravel",
        icon: thumbs.tech_icon2
    },
    {
        id: 3,
        title: "PHP",
        icon: thumbs.tech_icon3
    },
    {
        id: 4,
        title: "HTML",
        icon: thumbs.tech_icon4
    },
    {
        id: 5,
        title: "Sketch",
        icon: thumbs.tech_icon5
    },
    {
        id: 6,
        title: "Figma",
        icon: thumbs.tech_icon6
    },
    {
        id: 7,
        title: "Bootstrap",
        icon: thumbs.tech_icon7
    },
    {
        id: 8,
        title: "Tailwind",
        icon: thumbs.tech_icon8
    },
    {
        id: 9,
        title: "React",
        icon: thumbs.tech_icon9
    },
]

// 排序方式
export const SORT_TYPE = {
    // sortType 1商品价格低到高 2商品价格高到低 3销量多到少 4销量少到多 5库存多到少 6库存少到多 7创建时间早到晚 8创建时间晚到早 9排序高到低 10排序低到高
    price_low_to_high: 1,
    price_high_to_low: 2,
    sale_high_to_low: 3,
    sale_low_to_high: 4,
    stock_high_to_low: 5,
    stock_low_to_high: 6,
    create_time_dawn_to_dusk: 7,
    create_time_dusk_to_dawn: 8,
    sort_high_to_low: 9,
    stock_low_to_high: 10,
    rating_high_to_low: 11
}

export const sort_array = [
    {
        id: 0,
        title: "sort.all.item"
    },
    {
        id: SORT_TYPE.sale_high_to_low,
        title: "sort.best.selling"
    },
    {
        id: SORT_TYPE.rating_high_to_low,
        title: "sort.best.rating"
    }
]

export const ratings = [
    {
        id: 0,
        title: "rating.view.all",
        count: 8345,
    },
    {
        id: 1,
        title: "rating.1.star.and.above",
        count: 1234,
    },
    {
        id: 2,
        title: "rating.2.star.and.above",
        count: 1234,
    },
    {
        id: 3,
        title: "rating.3.star.and.above",
        count: 3245,
    },
    {
        id: 4,
        title: "rating.4.star.and.above",
        count: 4435,
    },
    {
        id: 5,
        title: "rating.5.star",
        count: 988,
    }
];

export const dates = [
    {
        id: 0,
        title: "date.any.date",
        count: 8345,
    },
    {
        id: 1,
        title: "date.in.the.last.year",
        count: 825,
    },
    {
        id: 2,
        title: "date.in.the.last.month",
        count: 1825,
    },
    {
        id: 3,
        title: "date.in.the.last.week",
        count: 234,
    },
    {
        id: 4,
        title: "date.in.the.last.day",
        count: 332,
    },
]


export const SIZES = {
    // global sizes
    base: 8,
    font: 14,
    radius: 12,
    padding: 24,
  
    // font sizes
    largeTitle: 40,
    h1: 30,
    h2: 22,
    h3: 16,
    h4: 14,
    h5: 12,
    body1: 30,
    body2: 22,
    body3: 16,
    body4: 14,
    body5: 12,
    body6: 10,
  
    weight200: 200,
    weight300: 300,
    weight400: 400,
    weight500: 500,
    weight600: 600,
  }
  
  export const COLORS = {
    primary: '#FF6C44', //orange
    transparentPrimray: 'rgba(227, 120, 75, 0.4)',
    orange: '#FFA133',
    lightOrange: '#FFA133',
    lightOrange2: '#FDDED4',
    lightOrange3: '#FFD9AD',
    green: '#27AE60',
    red: '#FF1717',
    blue: '#0f85ff',
    darkBlue: '#111A2C',
    darkGray: '#525C67',
    darkGray2: '#757D85',
    gray: '#898B9A',
    gray2: '#BBBDC1',
    gray3: '#CFD0D7',
    lightGray1: '#DDDDDD',
    lightGray2: '#F5F5F8',
    white2: '#FBFBFB',
    white: '#FFFFFF',
    black: '#000000',
    pink: '#f2826a',
    purple: '#7232dd',
    transparent: 'transparent',
    transparentBlack1: 'rgba(0, 0, 0, 0.1)',
    transparentBlack7: 'rgba(0, 0, 0, 0.7)',
    lightRed: '#fff1f0',
    lightPurple: '#f3efff',
    lightYellow: '#fff9ec',
    lightGreen: '#e6fef0',
    lightBlue: '#B4D8E6',
    linearOrange: 'linear-gradient(to right, #FFA133, #FFD9AD )',
    linearGreen: 'linear-gradient(to bottom, #27AE60, #e6fef0 )',
    linearPurple: 'linear-gradient(to right, #7232dd, #f3efff )',
    linearBlue: 'linear-gradient(to bottom, #0f85ff, #FBFBFB )',
    noticeBarColor: '#1989fa',
    noticeBarBackgroundColor: '#ecf9ff',
    lightBlue2: "#f1f5ea"
  }

export const FONTS = {
    sansFontFamily: "",
    size400:400,
    size200:200,
    size100:100
}

