import React from 'react'
import { useTranslation } from 'react-i18next'
import SocialLink from './SocialLink'
import { cn_social_links, en_social_links } from '../../constants/social_links'
import { COLORS } from '../../constants'
export default function SocialList({
    
    containerStyle,
}) {

    const { i18n, t } = useTranslation()
  return (
        <div 
            className="socail-share flx-align gap-3"
            style={{
                ...containerStyle
            }}
            >
            <span
                // className="socail-share__text text-heading fw-500"
                className='fw-500 '
                
                >
                {t("share.on")}:{" "}
            </span>
            <SocialLink 
                list={i18n.language === "zh" ? cn_social_links: en_social_links}
            />
        </div>
  )
}
