
import { Link } from "react-router-dom";
import Slider from "react-slick";

const PopularOne = ({
  title,
  list,
  explore_more
}) => {

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-right" />
      </button>
    );
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;

    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-left" />
      </button>
    );
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section className="popular padding-y-120 overflow-hidden">
      <div className="container container-two">
        <div className="section-heading style-left mb-64">
          <h5 className="section-heading__title">{title}</h5>
        </div>
        <div className="popular-slider arrow-style-two row gy-4">
          <Slider {...settings}>
            {
              list && list?.length > 0 && list.map((item,index) => {
                return (
                  <div key={`category-${index}`}>
                      <Link to={`/all-product/?category_id=${item.id}`} className="popular-item w-100">
                        <span className="popular-item__icon">
                          <img src={item.icon} alt="" />
                        </span>
                        <h6 className="popular-item__title font-18">{item.name}</h6>
                        <span className="popular-item__qty text-body">15,296</span>
                      </Link>
                  </div>
                )
              })
            }
            
          </Slider>
        </div>
        <div className="popular__button text-center">
          <Link
            to="/all-product"
            className="font-18 fw-600 text-heading hover-text-main text-decoration-underline font-heading"
          >
            {/* Explore More */}
            {explore_more}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PopularOne;
