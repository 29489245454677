import { Link } from "react-router-dom";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import ProductReviewCard from "./reviewCard";
import CommentsList from "../comments/list";
import { useEffect, useState } from "react";
import { ReactSVG } from 'react-svg'
import { GOOD_TYPE_NORMAL } from "../../constants";
import { t } from "i18next";
import { icons } from "../../constants/icons";
import ProductDetailSidebar from "./sidebar";
import PageBody from '../../components/pageBody'
const ProductDetails = ({
    type,
    reviewList,
    img,
    body,
    previewUrl,
    description,
    features,
    tags,
    supplier,
    moreGoodsList,
    commentTotal,
    commentList,
    skus,
    compatibilities,
    includefiles,
    techframeworks,
    fileSize,
    checkout,
    seckillGoods,
    isCheckouting,
    categoryLabel,
    createTime,
    updateTime
}) => {
    const [currentSku,setCurrentSku] = useState(skus[skus.length - 1])
    
    useEffect(() => {
        setCurrentSku(skus[skus.length - 1])
    })

    function handleCheckout(){
        
        if(type == GOOD_TYPE_NORMAL) {
            checkout(currentSku.id, null,null)
        } else {
            const seckillGood = seckillGoods[0]
            checkout(seckillGood.goods_sku_id, seckillGood.goods_id, seckillGood.seckill_id)
        }
    }
    
    return (
        <div className="product-details mt-32 padding-b-120">
            <div className="container container-two">
                <div className="row gy-4">
                    <div className="col-lg-8">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-product-details"
                                role="tabpanel"
                                aria-labelledby="pills-product-details-tab"
                                tabIndex={0}
                            >
                                {/* Product Details Content Start */}
                                <div className="product-details">
                                    <div className="product-details__thumb">
                                        <img src={img} alt="" />
                                    </div>
                                    <div className="product-details__buttons flx-align justify-content-center gap-3">
                                        <Link
                                            to={previewUrl}
                                            className="btn btn-main d-inline-flex align-items-center gap-2 pill px-sm-5 justify-content-center"
                                        >
                                        
                                            {t("live.preview")}
                                            <img src={icons.eye_outline} alt="" />
                                        </Link>
                                    
                                        <div
                                            style={{
                                                marginTop: 30
                                            }}
                                        >
                                             <PageBody 
                                                body={body}
                                            />
                                        </div>
                                       
                                        
                                    </div>
                                    <p className="product-details__desc">
                                        {description}
                                    </p>
                                    {/* <div className="product-details__item">
                                        <h5 className="product-details__title mb-3">
                                            Template Features
                                        </h5>
                                        <ul className="product-list">
                                            {
                                                features && features.length > 0 && features.map((item,index) => {
                                                    return (
                                                        <li key={`feature-${index}`} className="product-list__item">
                                                            {item?.title}
                                                        </li>
                                                    )
                                                } )
                                            }
                                            
                                        </ul>
                                    </div> */}
                                    {/* <div className="product-details__item">
                                        <h5 className="product-details__title mb-3">Tags</h5>
                                        <ul className="product-list">
                                            {
                                                tags && tags.length > 0 && tags.map((item,index) => {
                                                    return <li key={`tag-${index}`} className="product-list__item">{item.title}</li>
                                                })
                                            }
                                            
                                        </ul>
                                    </div> */}
                             
                                    <div className="more-item">
                                        <div className="flx-between mb-4">
                                            <h5 className="more-item__title">{t("more.items")}</h5>
                                            <Link
                                                to={`/profile/${supplier?.id}`}
                                                className="text-heading fw-500 hover-text-decoration-underline"
                                            >
                                                {t("view.author.profile")}
                                            </Link>
                                        </div>

                                        <div className="more-item__content flx-align">
                                            {
                                                moreGoodsList && moreGoodsList.length > 0 && moreGoodsList.map((item,index) => {
                                                   return <div className="more-item__item" key={`good-${index}`}>
                                                        <Link
                                                            target="_blank"
                                                            to={`/product-details/${item?.sn}`}
                                                            className="link w-100 h-100 d-block"
                                                        >
                                                            <img src={item.img} alt="" />
                                                        </Link>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* Product Details Content End */}
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-rating"
                                role="tabpanel"
                                aria-labelledby="pills-rating-tab"
                                tabIndex={0}
                            >
                                <div className="product-review-wrapper">
                                    {
                                        reviewList && reviewList.length > 0 && reviewList.map((item,index) => {
                                            return (
                                                <ProductReviewCard 
                                                    key={`review-card-${index}`}
                                                    review={item}
                                                />
                                            )
                                        })
                                    }
                                    
                                    
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-comments"
                                role="tabpanel"
                                aria-labelledby="pills-comments-tab"
                                tabIndex={0}
                            >
                                {/* Comment Start */}
                                <div className="comment mt-64 mb-64">
                                    <h5 className="mb-32">{commentTotal} Comments</h5>
                                    <CommentsList list={commentList} />
                                </div>
                                {/* Comment End */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        {/* ======================= Product Sidebar Start ========================= */}
                        <ProductDetailSidebar 
                            type={type}
                            currentSku={currentSku}
                            seckillGoods={seckillGoods}
                            handleCheckout={handleCheckout}
                            isCheckouting={isCheckouting}
                            supplier={supplier}
                            tags={tags}
                            features={features}
                            compatibilities={compatibilities}
                            includefiles={includefiles}
                            techframeworks={techframeworks}
                            fileSize={fileSize}
                            categoryLabel={categoryLabel}
                            createTime={createTime}
                            updateTime={updateTime}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ProductDetails;