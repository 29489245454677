import React from 'react'
import { icons } from '../../constants/icons'
import { useTranslation } from 'react-i18next'

import { LANGUAGES } from '../../constants'

export default function Global({

}) {
    const { i18n, t } = useTranslation()

    
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };


  return (
    <div className="language-select flx-align select-has-icon">
        <img
            src={icons.global}
            alt=""
            className="globe-icon white-version"
        />
        <img
            src={icons.global_white}
            alt=""
            className="globe-icon dark-version"
        />
        <select 
            className="select py-0 ps-2 border-0 fw-500" 
            defaultValue={i18n.language}
            onChange={onChangeLang}
            >

            {
                LANGUAGES.map((lang, index) => {
                return (
                    <option 
                    key={`lang-${index}`} 
                    value={lang.code}>
                        {lang.label}
                    </option>
                )
                })
            }
        </select>
    </div>
  )
}
