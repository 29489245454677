import React, { useState } from "react";
import Progress from "../components/Progress";
import Login from "../components/Login";
import BaseLayout from "../layout/BaseLayout";
import SEO from "../components/SEO";
import { t } from "i18next";
import { useEffectOnce } from "../utils/func";
import * as baseApi from '../api/base'
const LoginPage = () => {

  const [base,setBase] = useState({
    favicon:"",
    logo:"",
    site_name:"",
    description: ""
  })

  useEffectOnce(() => {
    loadInfo()
  })

  async function loadInfo() {
    const response = await baseApi.info();
    console.log("response info ---- ", response)
    if(response && response.code === 0) {
      setBase(response.result.info)
    }
  }

  return (
    <>
      <SEO
        title={`${base?.site_name} ${t("sign.in")}`} 
        description={base?.description}
      />
      {/* Progress */}
      <Progress />

      {/* Login */}
      <Login 
        favicon={base?.favicon}
        site_name={base.site_name}
        description={base.description}
      />
</>
  );
};

export default LoginPage;
