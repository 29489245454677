
import {
    get,
    post
} from '../utils/request'

// Axios Get Call - Get all jokes categories.
export const info = async (params = {}) => {
    try {
        const response = await get("/goodsresource/info", params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}