import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation  } from 'react-i18next'
export default function DashboardFooter({
    company_name
}) {
    const { t } = useTranslation();

  return (
    <div className="dashboard-footer bottom-footer-two mt-32 border-0 bg-white">
        <div className="bottom-footer__inner flx-between gap-3">
            <p className="bottom-footer__text font-14">
                {" "}
                {t("copyright", { year: new Date().getFullYear(),company: company_name })}
            </p>
            {/* <div className="footer-links gap-4">
                <Link to="/#" className="footer-link hover-text-heading font-14">
                    Terms of service
                </Link>
                <Link to="/#" className="footer-link hover-text-heading font-14">
                    Privacy Policy
                </Link>
                <Link to="/#" className="footer-link hover-text-heading font-14">
                    cookies
                </Link>
            </div> */}
        </div>
    </div>
  )
}
