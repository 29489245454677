import React from 'react'
import BlogHorizontalCard from '../blog/horizontalCard'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
export default function ArticleList({
    list
}) {
    const { t } = useTranslation()
  return (
    <section className="article padding-y-120 section-bg">
    <div className="container container-two">
      <div className="section-heading style-left style-flex flx-between align-items-end gap-3">
        <div className="section-heading__inner">
          <h3 className="section-heading__title">
            {t("browse.all.latest.blogs.and.articles")}
          </h3>
        </div>
        <Link to="/blogs" className="btn btn-outline-light btn-lg pill">
          
          {t("browse.all.articles")}
        </Link>
      </div>
      <div className="article-item-wrapper">
        {
          list && list.length > 0 && list.map((item,index) => {
            return (
                <BlogHorizontalCard 
                  key={`blog-${index}`}
                  item={item}
                />
            )
          })
        }
        
        
      </div>
    </div>
  </section>
  )
}
