import React from "react";
import BrandSectionOne from "../../components/brand/BrandSectionOne";
import BreadcrumbFour from "../../components/breadcrumb/BreadcrumbFour";
import Cart from "../../components/Cart";
import BaseLayout from '../../layout/BaseLayout'

const CartPage = () => {

  return (
      <BaseLayout
        onBaseInfo={info => {
          console.log("22 base info is", info)
        }}
      >
          
        {/* BreadcrumbFour */}
        <BreadcrumbFour />

        {/* Cart */}
        <Cart />

        {/* BrandSectionOne */}
        <BrandSectionOne />
      </BaseLayout>
  );
};

export default CartPage;
