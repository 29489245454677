
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProductAuthorCard from "./product/authorCard";
import { gradient, icons, shapes } from "../constants/icons";
const FeaturedAuthor = ({
  title,
  description,
  view_profile,
  fallow,
  author,
  list,
  author_register_from,
  author_top_featured
}) => {
  const textRef = useRef(null);

  useEffect(() => {
    const text = textRef.current;

    if (text) {
      text.innerHTML = text.innerText
        .split("")
        .map(
          (char, i) => `<span style="transform:rotate(${i * 11.5}deg)">${char}</span>`
        )
        .join("");
    }
  }, []);
  return (
    <section className="top-author padding-y-120 section-bg position-relative z-index-1">
      <img
        src={gradient.featured_gradient}
        alt=""
        className="bg--gradient white-version"
      />
      <img
        src={shapes.spider_net}
        alt=""
        className="spider-net position-absolute top-0 start-0 z-index--1 white-version"
      />
      <img
        src={shapes.spider_net_white2}
        alt=""
        className="spider-net position-absolute top-0 start-0 z-index--1 dark-version"
      />
      <img
        src={shapes.curve_pattern3}
        alt=""
        className="position-absolute top-0 end-0 z-index--1"
      />
      <img
        src={shapes.element1}
        alt=""
        className="element two"
      />
      <div className="container container-two">
        <div className="row gy-4 align-items-center">
          <div className="col-xl-5">
            <div className="section-content">
              <div className="section-heading style-left">
                <h3 className="section-heading__title">{title}</h3>
                <p className="section-heading__desc font-18 w-sm">
                  {description}
                </p>
              </div>
              <div className="author-info d-flex align-items-center gap-3">
                <div className="author-info__thumb">
                  <img src={author?.img} alt="" />
                   
                </div>
                <div className="author-info__content">
                  <h4 className="author-info__name mb-1">{author?.title}</h4>
                  <span className="author-info__text">{author_register_from}</span>
                </div>
              </div>
              <div className="flx-align gap-2 mt-48">
                <Link
                  to={`/profile?user_id=${author?.id}`}
                  className="btn btn-main btn-lg pill fw-300"
                >
                  {view_profile}
                </Link>
                <button
                  type="button"
                  className="follow-btn btn btn-outline-light btn-lg pill"
                >
                  {fallow}
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="circle-content position-relative">
              <div className="circle static-circle">
                <div className="circle__badge">
                  <img src={icons.featured_badge} alt="" />
                </div>
                <div className="circle__text" ref={textRef}>
                  <p>{author_top_featured}</p>
                </div>
              </div>
              <div className="row gy-4 card-wrapper">
                {
                  list && list.length > 0 && list.map(item => {
                    return (
                      <ProductAuthorCard 
                        key={`card-${item.id}`}
                        good={item}
                      />
                    )
                  })
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedAuthor;
