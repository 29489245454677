import React from 'react'

import ThemeToggle from '../ThemeToggle'
import { icons } from '../../constants/icons'
import { useTranslation } from 'react-i18next'
import UserProfile from '../profile/UserProfile'
import Global from '../global'
export default function DashboardNav({
    dashboardControl,
    userId,
    userAvatar
}) {

    const { t } = useTranslation()

  return (
    <div className="dashboard-nav bg-white flx-between gap-md-3 gap-2">
        <div className="dashboard-nav__left flx-align gap-md-3 gap-2">
            <button onClick={dashboardControl}
                type="button"
                className="icon-btn bar-icon text-heading bg-gray-seven flx-center"
            >
                <i className="las la-bars" />
            </button>
            <button onClick={dashboardControl}
                type="button"
                className="icon-btn arrow-icon text-heading bg-gray-seven flx-center"
            >
                <img src={icons.angle_right} alt="" />
            </button>
            <form action="#" className="search-input d-sm-block d-none">
                <span className="icon">
                    <img
                        src={icons.search_dark}
                        alt=""
                        className="white-version"
                    />
                    <img
                        src={icons.search_dark_white}
                        alt=""
                        className="dark-version"
                    />
                </span>
                <input
                    type="text"
                    className="common-input common-input--md common-input--bg pill w-100"
                    placeholder={`${t("search.here")}...`}
                />
            </form>
        </div>
        <div className="dashboard-nav__right" >
            <div className="header-right flx-align">
                <div className="header-right__inner gap-sm-3 gap-2 flx-align d-flex">
                    {/* Light Dark Mode */}
                    <ThemeToggle />

                    <UserProfile 
                        id={userId}
                        avatar={userAvatar}
                    />
                    
                    <Global />
                </div>
            </div>
        </div>
    </div>
  )
}
