import React from 'react'
import { icons } from '../../constants/icons'
import { TOKEN } from '../../constants'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export default function ReactDropDown({
    show,
}) {
    const { t } = useTranslation()

  return (
    <ul className={`user-profile-dropdown ${show && "show"} `}>
            <li className="sidebar-list__item">
                <Link to="/dashboard" className="sidebar-list__link">
                    <span className="sidebar-list__icon">
                        <img
                            src={icons.sidebar_icon2}
                            alt=""
                            className="icon"
                        />
                        <img
                            src={icons.sidebar_icon2_active}
                            alt=""
                            className="icon icon-active"
                        />
                    </span>
                    <span className="text">{t("dashboard")}</span>
                </Link>
            </li>
            <li className="sidebar-list__item">
                <Link to="/customer-setting" className="sidebar-list__link">
                    <span className="sidebar-list__icon">
                        <img
                            src={icons.sidebar_icon10}
                            alt=""
                            className="icon"
                        />
                        <img
                            src={icons.sidebar_icon10_active}
                            alt=""
                            className="icon icon-active"
                        />
                    </span>
                    <span className="text">{t("settings")}</span>
                </Link>
            </li>
            <li className="sidebar-list__item">
                <Link 
                    className="sidebar-list__link" 
                    to={"/login"}
                    onClick={() => {
                        localStorage.removeItem(TOKEN)
                }}>
                    <span className="sidebar-list__icon">
                        <img
                            src={icons.sidebar_icon13}
                            alt=""
                            className="icon"
                        />
                        <img
                            src={icons.sidebar_icon13_active}
                            alt=""
                            className="icon icon-active"
                        />
                    </span>
                    <span className="text"> {t("logout")}</span>
                </Link>
            </li>
        </ul>
    
  )
}
