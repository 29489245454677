import React from 'react'

export default function RichText({
    options,
    data
}) {
    const content = data.html_content;

  return (
    <div
        dangerouslySetInnerHTML={{
            __html: content
        }}
    >

    </div>
  )
}
