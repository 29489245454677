
import {
    get
} from '../utils/request'


export const list = async (params = { }) => {
    try {
        const response = await get("/blog/list", params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}

export const info = async (params = { }) => {
    try {
        const response = await get("/blog/info", params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}