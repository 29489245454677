import { Link } from "react-router-dom";

const BecomeSellerOne = ({
  seller_earn_title,
  seller_earn_description,
  seller_register,
  affiliate_earn_title,
  affiliate_earn_description,
  affiliate_register,
  support_7_24,
  support_message,
  email
}) => {
  return (
    <section className="seller padding-y-120">
      <div className="container container-two">
        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="seller-item position-relative z-index-1">
              <img
                src="../assets/images/shapes/seller-bg.png"
                className="position-absolute start-0 top-0 z-index--1"
                alt=""
              />
              <h3 className="seller-item__title">
                {seller_earn_title}
              </h3>
              <p className="seller-item__desc fw-500 text-heading">
                {seller_earn_description}
              </p>
              <Link
                to="/register"
                className="btn btn-static-outline-black btn-xl pill fw-600"
              >
                {seller_register}
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="seller-item bg-two position-relative z-index-1">
              <img
                src="../assets/images/shapes/seller-bg-two.png"
                className="position-absolute start-0 top-0 z-index--1"
                alt=""
              />
              <h3 className="seller-item__title">{affiliate_earn_title}</h3>
              <p className="seller-item__desc fw-500 text-heading">
                {affiliate_earn_description}
              </p>
              <Link
                to="/register"
                className="btn btn-static-outline-black btn-xl pill fw-600"
              >
                {affiliate_register}
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="support position-relative z-index-1">
              <img
                src="../assets/images/shapes/spider-net-sm.png"
                alt=""
                className="spider-net position-absolute top-0 end-0 z-index--1"
              />
              <img
                src="../assets/images/shapes/arrow-shape.png"
                alt=""
                className="arrow-shape"
              />
              <div className="row align-items-center">
                <div className="col-lg-1 d-lg-block d-none" />
                <div className="col-lg-3 col-md-4 d-md-block d-none">
                  <div className="support-thumb text-center">
                    <img src={require('../assets/images/thumbs/support-img.png')} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 d-lg-block d-none" />
                <div className="col-lg-5 col-md-8">
                  <div className="support-content">
                    <h3 className="support-content__title mb-3">
                      {support_7_24}
                    </h3>
                    <p className="support-content__desc">
                      {support_message}
                    </p>
                    <Link
                      to={`mailto:${email}`}
                      className="btn btn-static-black btn-lg fw-300 pill lowercase"
                    >
                      {email}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BecomeSellerOne;
