import React, { useState } from "react";
import Preloader from "../../helper/Preloader";
import MasterLayout from "../../layout/MasterLayout";
import CustomerOrderList from "../../components/order/CustomerOrderList";
import { useEffectOnce } from "../../utils/func";
import * as orderApi from '../../api/order'
import SEO from '../../components/SEO'
import { t } from "i18next";
const CustomerOrderPage = () => {

  const [result,setResult] = useState({
    list:[],
    total_number: 0  
  })
  useEffectOnce(() => {
    
    loadList({})
  },[])

  async function loadList(params = {}) {
    const response = await orderApi.list(params)
    console.log("19 order list response is", response)
    if(response && response.code === 0) {
      setResult(response.result)
    }
  }
  

  return (

    <>
      <MasterLayout>

        <SEO 
          title={t("orders")}
        />

        {/* Preloader */}
        <Preloader />

        {/* DashboardDownload */}
        <CustomerOrderList
          list={result.list}
          total_number={result.total_number}
        />

      </MasterLayout>

    </>
  );
};

export default CustomerOrderPage;
