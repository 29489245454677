import React from "react";
import Progress from "../components/Progress";
import Register from "../components/Register";
import SEO from "../components/SEO";
import { useTranslation } from "react-i18next";
const RegisterPage = () => {

  const { t } = useTranslation()

  return (

    <>
      <SEO 
        title={t("sign.up")}
      />
      {/* Progress */}
      <Progress />

      {/* Login */}
      <Register />
    </>
  );
};

export default RegisterPage;
