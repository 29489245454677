import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { icons } from '../../constants/icons'
import RecentBlogCard from './RecentBlogCard'
export default function BlogSidebar({
    latest_blog_list,
    category_list
}) {

    const { t } = useTranslation()
    function renderSearch() {
        return (
            <div className="common-sidebar p-0">
            <form action="#" autoComplete="off">
                <div className="search-box w-100">
                    <input
                        type="text"
                        className="common-input border-0"
                        placeholder={`${t("type.here")}...`}
                    />
                    <button
                        type="submit"
                        className="icon line-height-1 rounded-icon white-version"
                    >
                        <img src={icons.search_dark} alt="" />
                    </button>
                    <button
                        type="submit"
                        className="icon line-height-1 rounded-icon dark-version"
                    >
                        <img
                            src={icons.search_dark_white}
                            alt=""
                        />
                    </button>
                </div>
            </form>
        </div>
        )
    }

    function renderRecentBlogs() {
        return (
            <div className="common-sidebar">
                <h6 className="common-sidebar__title">{t("recent.blogs")}</h6>
                
                {
                    latest_blog_list && latest_blog_list.length > 0 && latest_blog_list.map((blog,index) => {
                        return (
                            <RecentBlogCard 
                                key={`blog-${index}`}
                                blog={blog}
                            />
                        )
                    })
                }
            </div>
        )
    }

    function renderCategories() {
        return (
            <div className="common-sidebar">
            <h6 className="common-sidebar__title">{t("categories")}  </h6>
            <ul className="category-list">
                {
                    category_list && category_list.length > 0 && category_list.map((item,index) => {
                        return (
                            <li className="category-list__item" key={`category-${index}`}>
                                <Link
                                    to={`/blogs?category_id=${item.id}`}
                                    className="category-list__link flx-align flex-nowrap gap-2 text-body hover-text-main"
                                >
                                    <span className="icon font-12">
                                        {" "}
                                        <i className="fas fa-chevron-right" />
                                    </span>
                                    <span className="text">{`${item.title} (${item.count})`}</span>
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
        )
    }

    function renderTags() {

        const temp = t("popular.tags.array")
        const arr = temp.split("#")

        return (
            <div className="common-sidebar">
            <h6 className="common-sidebar__title">{t("popular.tags")}</h6>
            <ul className="tag-list flx-align gap-2">
                {
                    arr && arr.length > 0 && arr.map((item,index) => {
                        return (
                            <li className="tag-list__item" key={`li-${index}`}>
                                <Link
                                    to={`/blogs?tag=${item}`}
                                    className="tag-list__link pill px-3 py-2 font-14 fw-500"
                                >
                                    {item}
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
        )

    }

  return (
    <div className="common-sidebar-wrapper">

        {renderSearch()}

        {renderRecentBlogs()}

        {renderCategories()}

        {renderTags()}
        
    </div>
  )
}
