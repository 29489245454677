import { Link } from "react-router-dom";
import ThemeToggle from "./ThemeToggle";
import { ToastContainer, toast } from 'react-toastify';
import { useState } from "react";
import * as userApi from '../api/user'
import { REDDIRECT_URL, TOKEN } from "../constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message } from "react-message-popup";

const Register = () => {
  const navigate = useNavigate()
  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")

  const { t } = useTranslation()

  async function onSubmit(e) {
    // toast(JSON.stringify(e))
    if(!name) {
      const title = t("full.name") + t("is.required")
      message.warning(title)
      return;
    }

    if(!email) {
      const title = t("email") + t("is.required")
      message.warning(title)
      return;
    }

    if(!password) {
      const title = t("password") + t("is.required")
      message.warning(title)
      return;
    }

    const payload = {
      nickname: name,
      username: email,
      password
    }

    try {
      const response = await userApi.register(payload)
      console.log("response is", response)
      if(response && response.code === 0) {
        //  toast("register success")
         message.success(t("success"))
         localStorage.setItem(TOKEN,response.result.access_token)
         setTimeout(() => {
          const redirectUrl = localStorage.getItem(REDDIRECT_URL)
          navigate(redirectUrl)
         }, 2000)
      } else if(response.code === 10017) {
        // toast(response?.msg)
        message.warning(t("already.a.member"))
        navigate("/login")
      }
    } catch (error) {
      toast(error?.message)
    }
    
  }

  return (
    <>
      {/* ================================== Account Page Start =========================== */}
      <section className="account d-flex">
        <img
          src={require('../assets/images/thumbs/account-img.png')}
          alt=""
          className="account__img"
        />
        <div className="account__left d-md-flex d-none flx-align section-bg position-relative z-index-1 overflow-hidden">
          <img
            src={require('../assets/images/shapes/pattern-curve-seven.png')}
            alt=""
            className="position-absolute end-0 top-0 z-index--1 h-100"
          />
          <div className="account-thumb">
            <img src={require('../assets/images/thumbs/banner-img.png')} alt="" />
            <div className="statistics animation bg-main text-center">
              <h5 className="statistics__amount text-white">50k</h5>
              <span className="statistics__text text-white font-14">
                {t("customers")}
              </span>
            </div>
          </div>
        </div>
        <div className="account__right padding-t-120 flx-align">
          <div className="dark-light-mode">
            {/* Light Dark Mode */}
            <ThemeToggle />
          </div>
          <div className="account-content">
            <Link to="/" className="logo mb-64">
              <img
                src={require('../assets/images/logo/sellmycode.png')} //"assets/images/logo/logo.png"
                alt=""
                className="white-version"
              />
              <img
                src={require('../assets/images/logo/sellmycode.png')} //"assets/images/logo/white-logo-two.png"
                alt=""
                className="dark-version"
              />
            </Link>
            <h4 className="account-content__title mb-48 text-capitalize">
              {t("create.a.free.account")}
            </h4>
            {/* <form> */}
              <div className="row gy-4">
                <div className="col-12">
                  <label
                    htmlFor="name"
                    className="form-label mb-2 font-18 font-heading fw-600"
                  >
                    {t("full.name")}
                  </label>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="common-input common-input--bg common-input--withIcon"
                      id="name"
                      placeholder={t("full.name")}
                      onChange={e => { setName(e.target.value )}}
                    />
                    <span className="input-icon">
                      <img src="../assets/images/icons/user-icon.svg" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <label
                    htmlFor="email"
                    className="form-label mb-2 font-18 font-heading fw-600"
                  >
                    {t("email")}
                  </label>
                  <div className="position-relative">
                    <input
                      type="email"
                      className="common-input common-input--bg common-input--withIcon"
                      id="email"
                      placeholder="infoname@mail.com"
                      onChange={e => { setEmail(e.target.value )}}
                    />
                    <span className="input-icon">
                      <img src="../assets/images/icons/envelope-icon.svg" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <label
                    htmlFor="your-password"
                    className="form-label mb-2 font-18 font-heading fw-600"
                  >
                    {t("password")}
                  </label>
                  <div className="position-relative">
                    <input
                      type="password"
                      className="common-input common-input--bg common-input--withIcon"
                      id="your-password"
                      placeholder={t("enter.your.password")}
                      onChange={e => { setPassword(e.target.value )}}
                    />
                    <span
                      className="input-icon toggle-password cursor-pointer"
                      id="#your-password"
                    >
                      <img src="../assets/images/icons/lock-icon.svg" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="common-check my-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="checkbox"
                      id="agree"
                    />
                    <label
                      className="form-check-label mb-0 fw-400 font-16 text-body"
                      htmlFor="agree"
                    >
                      {t("i.agree.to.terms.conditions")}
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-main btn-lg w-100 pill"
                    onClick={() => onSubmit()}
                  >
                    {" "}
                    
                    {t("create.an.account")}
                  </button>
                </div>
                {/* <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-outline-light btn-lg-icon btn-lg w-100 pill"
                  >
                    <span className="icon icon-left">
                      <img src="assets/images/icons/google.svg" alt="" />
                    </span>
                    Sign up with google
                  </button>
                </div> */}
                <div className="col-sm-12 mb-0">
                  <div className="have-account">
                    <p className="text font-14">
                      {t("already.a.member")}?{" "}
                      <Link
                        className="link text-main text-decoration-underline  fw-500"
                        to="/login"
                      >
                        {t("sign.in")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            {/* </form> */}
          </div>
        </div>
      </section>
      {/* ================================== Account Page End =========================== */}
    </>
  );
};

export default Register;
