

// export const gra

import { obs_prefix_url } from './config_url'

export const logo = {
    favicon: "assets/images/logo/favicon.png",
    logo: "../assets/images/logo/logo.png",
    logo_two: "assets/images/logo/white-logo-two.png"
}

export const gradient = {
    banner_gradient: require('../assets/images/gradients/banner-gradient.png'),
    banner_two_gradient: require('../assets/images/gradients/banner-two-gradient.png'),
    
    breadcrumb_gradient_bg: require('../assets/images/gradients/breadcrumb-gradient-bg.png'),
    // 
    selling_gradient: require('../assets/images/gradients/selling-gradient.png'),
    featured_gradient: require('../assets/images/gradients/featured-gradient.png'),

    //product-gradient
    product_gradient: require('../assets/images/gradients/product-gradient.png'),
    footer_gradient: require('../assets/images/gradients/footer-gradient.png'),
    thank_you_gradient: require('../assets/images/gradients/thank-you-gradient.png'),
    
}

export const shapes = {
    element_moon1: require('../assets/images/shapes/element-moon1.png'),
    element_moon2: require('../assets/images/shapes/element-moon2.png'),
    element_moon3: require('../assets/images/shapes/element-moon3.png'),
    element_moon4: require('../assets/images/shapes/element-moon4.png'),

    element1:require('../assets/images/shapes/element1.png'),
    element2:require('../assets/images/shapes/element2.png'),
    pattern: require("../assets/images/shapes/pattern.png"),
    
    //
    curve_pattern1:require('../assets/images/shapes/curve-pattern1.png'),
    curve_pattern2:require('../assets/images/shapes/curve-pattern2.png'),
    curve_pattern3:require('../assets/images/shapes/pattern-curve-three.png'),
    curve_pattern4:require('../assets/images/shapes/pattern-curve-four.png'),
    curve_pattern5:'../assets/images/shapes/pattern-five.png',
    curve_pattern7: '../assets/images/shapes/pattern-curve-seven.png',
    
    
    // 
    dots: require('../assets/images/shapes/dots.png'),
    dots_white: require('../assets/images/shapes/dots-white.png'),

    // spider-net.png
    spider_net: require('../assets/images/shapes/spider-net.png'),
    spider_net_white: require('../assets/images/shapes/spider-net-white.png'),
    spider_net_white2: require('../assets/images/shapes/spider-net-white2.png'),

    pattern_five: require('../assets/images/shapes/pattern-five.png'),
    
}
// `${obs_prefix_url}/icons/`
export const icons ={
    user: `${obs_prefix_url}/icons/user.svg`,
    cart:`${obs_prefix_url}/icons/cart.svg`,
    cart_icon: `${obs_prefix_url}/icons/cart-icon.svg` ,
    cart_white: `${obs_prefix_url}/icons/cart-white.svg` ,
    add_to_cart: `${obs_prefix_url}/icons/add-to-cart.svg`,
    search: `${obs_prefix_url}/icons/search.svg`,
    search_two:`${obs_prefix_url}/icons/search-two.svg`,
    filter: `${obs_prefix_url}/icons/filter.svg`,
    eye_outline:`${obs_prefix_url}/icons/eye-outline.svg`, 

    check_icon:`${obs_prefix_url}/icons/check-icon.svg`, 
    check_cirlce:`${obs_prefix_url}/icons/check-cirlce.svg`,
    share_icon: `${obs_prefix_url}/icons/share-icon.svg`,

    sidebar_icon1:`${obs_prefix_url}/icons/sidebar-icon1.svg`, 
    sidebar_icon1_active: `${obs_prefix_url}/icons/sidebar-icon-active1.svg`, 
    sidebar_icon2: `${obs_prefix_url}/icons/sidebar-icon2.svg`,
    sidebar_icon2_active: `${obs_prefix_url}/icons/sidebar-icon-active2.svg`,

    sidebar_icon3:`${obs_prefix_url}/icons/sidebar-icon3.svg`,  
    sidebar_icon3_active: `${obs_prefix_url}/icons/sidebar-icon-active3.svg`, 
    sidebar_icon4:`${obs_prefix_url}/icons/sidebar-icon4.svg` ,
    sidebar_icon4_active:`${obs_prefix_url}/icons/sidebar-icon-active4.svg`,
    sidebar_icon5:`${obs_prefix_url}/icons/sidebar-icon5.svg`,
    sidebar_icon5_active:`${obs_prefix_url}/icons/sidebar-icon-active5.svg`,
    sidebar_icon10:`${obs_prefix_url}/icons/sidebar-icon10.svg`,
    sidebar_icon10_active:`${obs_prefix_url}/icons/sidebar-icon-active10.svg`,
    sidebar_icon11:`${obs_prefix_url}/icons/sidebar-icon11.svg`,
    sidebar_icon11_active:`${obs_prefix_url}/icons/sidebar-icon-active11.svg`,
    sidebar_icon12:`${obs_prefix_url}/icons/sidebar-icon12.svg`,
    sidebar_icon12_active:`${obs_prefix_url}/icons/sidebar-icon-active12.svg`,

    sidebar_icon13:`${obs_prefix_url}/icons/sidebar-icon13.svg`,
    sidebar_icon13_active:`${obs_prefix_url}/icons/sidebar-icon-active12.svg`,

    global:`${obs_prefix_url}/icons/globe.svg`,
    global_white:`${obs_prefix_url}/icons/globe-white.svg` ,

    reply_icon:`${obs_prefix_url}/icons/reply-icon.svg`,
    clock:`${obs_prefix_url}/icons/clock.svg`,

    search_dark:`${obs_prefix_url}/icons/search-dark.svg`,
    search_dark_white:`${obs_prefix_url}/icons/search-dark-white.svg`,
    featured_badge:`${obs_prefix_url}/icons/featured-badge.png`,
    download:`${obs_prefix_url}/icons/download.svg`,
    download_white:`${obs_prefix_url}/icons/download-white.svg`,
    
    angle_right:`${obs_prefix_url}/icons/angle-right.svg`,
    search:`${obs_prefix_url}/icons/search.svg`,

    process_white1: `${obs_prefix_url}/icons/process-white1.svg`,
    process_white2: `${obs_prefix_url}/icons/process-white2.svg`,
    process_white3: `${obs_prefix_url}/icons/process-white3.svg`,
    process_white4: `${obs_prefix_url}/icons/process-white4.svg`,
    process2: `${obs_prefix_url}/icons/process2.svg`,
    process3: `${obs_prefix_url}/icons/process3.svg`,
    process4: `${obs_prefix_url}/icons/process4.svg`,
}

export const thumbs = {
    account_img: require('../assets/images/thumbs/account-img.png'),

    tech_icon1: require('../assets/images/thumbs/tech-icon1.png'),
    tech_icon_white1: require('../assets/images/thumbs/tech-icon-white1.png'),
    tech_icon2: require('../assets/images/thumbs/tech-icon2.png'),
    tech_icon3: require('../assets/images/thumbs/tech-icon3.png'),
    tech_icon_white3: require('../assets/images/thumbs/tech-icon-white3.png'),
    tech_icon4: require('../assets/images/thumbs/tech-icon4.png'),
    tech_icon5: require('../assets/images/thumbs/tech-icon5.png'),
    tech_icon6: require('../assets/images/thumbs/tech-icon6.png'),
    tech_icon7: require('../assets/images/thumbs/tech-icon7.png'),
    tech_icon8: require('../assets/images/thumbs/tech-icon8.png'),
    tech_icon9: require('../assets/images/thumbs/tech-icon9.png'),

    brand_img1: require('../assets/images/thumbs/brand-img1.png'),
    brand_img2: require('../assets/images/thumbs/brand-img2.png'),
    brand_img3: require('../assets/images/thumbs/brand-img3.png'),
    brand_img4: require('../assets/images/thumbs/brand-img4.png'),
    brand_img5: require('../assets/images/thumbs/brand-img5.png'),

    brand_white_img1: require("../assets/images/thumbs/brand-white-img1.png"),
    brand_white_img2: require("../assets/images/thumbs/brand-white-img2.png"),
    brand_white_img3: require("../assets/images/thumbs/brand-white-img3.png"),
    brand_white_img4: require("../assets/images/thumbs/brand-white-img4.png"),
    brand_white_img5: require("../assets/images/thumbs/brand-white-img5.png"),

    blog_1: require('../assets/images/thumbs/blog1.png'),

    banner_img: require('../assets/images/thumbs/banner-img.png'),

    badge1: require('../assets/images/thumbs/badge1.png'),
    badge2: require('../assets/images/thumbs/badge2.png'),
    badge3: require('../assets/images/thumbs/badge3.png'),
    badge4: require('../assets/images/thumbs/badge4.png'),
    badge5: require('../assets/images/thumbs/badge5.png'),
    badge6: require('../assets/images/thumbs/badge6.png'),
    badge7: require('../assets/images/thumbs/badge7.png'),
    badge8: require('../assets/images/thumbs/badge8.png'),

    user_profile: require("../assets/images/thumbs/user-profile.png"),
    thank_evenelope: require("../assets/images/thumbs/thank-evenelope.png"),
 
}