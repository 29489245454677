import CommentCard from "./card"

import { useTranslation } from "react-i18next"

const CommentsList = ({ list, total_number, onReply }) => {

    const { t } = useTranslation()
  
    return (
        <div className="comment mt-64 mb-64">
            <h5 className="mb-32">{total_number} {t("comments")}</h5>
            <ul className="comment-list">
                {
                    list && list.length > 0 && list.map((item,index) => {

                        if(item.pid === 0) {
                            return (
                                <CommentCard
                                    key={`comment-card-${index}`}
                                    comment={item}
                                    onReply={onReply}
                                />
                            )
                        } else {
                            return  <li key={`ll-${index}`} style={{ marginTop: 10 }}>
                                <ul className="comment-list comment-list--two">
                                    <CommentCard 
                                        comment={item}
                                        onReply={onReply}
                                    />
                                
                                </ul>
                        </li>
                        }
                    })
                }
            </ul>
        </div>
    )
}

export default CommentsList