
import React from 'react'
import Icon, { AlipayCircleOutlined, PlusOutlined, WechatOutlined } from '@ant-design/icons';

import { Button, Space, message, Drawer } from 'antd';
import { t } from 'i18next';
import { SIZES } from '../../constants';
import { FaPaypal } from "react-icons/fa";


export default function SettleDialog({
    paynameList,
    visible,
    onClose,
    onClick,
}) {

    function renderIcon(name) {
        if(name == "alipay") {
            return <AlipayCircleOutlined />
        }
        if(name == "wechat") {
            return <WechatOutlined />;
        }
        if(name == "paypal") {
            return <FaPaypal />
        }
        return null;
    }
  return (
    <Drawer
        title={t("select.pay.method")}
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={visible}
        key={"bottom"}
      >
        {
            paynameList && paynameList.length > 0 && paynameList.map((item,index) => {
                return (<Button 
                    key={`button-${index}`}
                    style={{
                        marginTop: SIZES.padding
                    }}
                    block 
                    type="primary" 
                    icon={renderIcon(item.name)} 
                    onClick={() => {
                        onClick(item.name)
                    }}
                    size={"large"}>
                    {item.title}
                </Button>)
            })
        }
        
      </Drawer>
  )
}
