import React from 'react'


/**
 * 适应于产品详情图
*/
export default function Image({
    url
}) {
  return (
    <div className="blog-details-content__thumb mb-32">
      <img src={url} alt="" />
    </div>
  )
}
