import { Link } from "react-router-dom";
import { gradient } from "../../constants/icons";
import { useTranslation } from "react-i18next";
const BreadcrumbEight = () => {
  const { t } = useTranslation()
  return (
    <section className="breadcrumb border-bottom p-0 d-block section-bg position-relative z-index-1">
      <div className="breadcrumb-two">
        <img
          src={gradient.breadcrumb_gradient_bg}
          alt=""
          className="bg--gradient"
        />
        <div className="container container-two">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="breadcrumb-two-content text-center">
                <ul className="breadcrumb-list flx-align gap-2 mb-2 justify-content-center">
                  <li className="breadcrumb-list__item font-14 text-body">
                    <Link
                      to="/"
                      className="breadcrumb-list__link text-body hover-text-main"
                    >
                      {t("home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-list__item font-14 text-body">
                    <span className="breadcrumb-list__icon font-10">
                      <i className="fas fa-chevron-right" />
                    </span>
                  </li>
                  <li className="breadcrumb-list__item font-14 text-body">
                    <span className="breadcrumb-list__text">{t("contact")}</span>
                  </li>
                </ul>
                <h3 className="breadcrumb-two-content__title mb-0 text-capitalize">
                  {t("contact.us")}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default BreadcrumbEight;