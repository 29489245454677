import { Link } from "react-router-dom";
import { shapes, thumbs } from "../../constants/icons";
import VerticalCard from './VeritcalCard'
import Pagination from "../pagination";
const AllBlogList = ({
  list ,
  total_number,
  onFilter,
}) => {
  return (
    <section className="blog padding-y-120 section-bg position-relative z-index-1 overflow-hidden">
      <img
        src={shapes.pattern_five}
        className="position-absolute end-0 top-0 z-index--1"
        alt=""
      />
      <div className="container container-two">
        <div className="row gy-4">
          {
            list && list.length > 0 && list.map((blog,index) => {
              return (
                <VerticalCard 
                  key={`vertical-card-${index}`}
                  blog={blog}
                />
              )
            })
          }
          
        </div>
        
        <Pagination 
          totalNumber={total_number}
          onFilter={onFilter}
        />
        
        {/* Pagination Start */}
        {/* <nav aria-label="Page navigation example">
          <ul className="pagination common-pagination">
            <li className="page-item">
              <Link className="page-link" to="#">
                1
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="#">
                2
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="#">
                3
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="#">
                4
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="#">
                5
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link flx-align gap-2 flex-nowrap" to="#">
                Next
                <span className="icon line-height-1 font-20">
                  <i className="las la-arrow-right" />
                </span>
              </Link>
            </li>
          </ul>
        </nav> */}
        {/* Pagination End */}
      </div>
    </section>

  );
}

export default AllBlogList;