import { Link } from "react-router-dom";
import { icons } from "../constants/icons";
import { locale } from "../utils/time";
import { useTranslation } from "react-i18next";
import PageBody from "./pageBody";
import TagList from "./tag/list";
import SocialList from "./social/SocialList";
import CommentPost from "./comments/post";
import CommentList from "./comments/list";
import ArticleList from "./article/ArticleList";
import BlogSidebar from "./blog/Sidebar";
const BlogDetailsSidebar = ({
  user_info,
  title,
  description,
  body,
  tags,
  create_time,
  onAddComment,
  comments,
  onReply,
  latest_blog_list,
  category_list
}) => {

    const { i18n } = useTranslation();

    return (
        <>
            {/* Blog Details Section */}
            <section className="blog-details padding-y-120 position-relative overflow-hidden">
                <div className="container container-two">
                    {/* blog details top Start */}
                    <div className="blog-details-top mb-64">
                        <div className="blog-details-top__info flx-align gap-3 mb-4">
                            <div className="blog-details-top__thumb flx-align gap-2">
                                <img src={user_info?.avatar} alt="" />
                                <span className="text-heading fw-500">{user_info?.nickname}</span>
                            </div>
                            <span className="blog-details-top__date flx-align gap-2">
                                <img src={icons.clock} alt="" />
                                { locale(create_time,i18n.language )}
                            </span>
                        </div>
                        <h2 className="blog-details-top__title mb-4 text-capitalize">
                            {title}
                        </h2>
                        <p style={{
                            textIndent: '30px',
                            fontWeight: 'bold'
                            }}>
                            {description}
                        </p>
                    </div>
                    {/* blog details top End */}
                    <div className="row gy-4">
                        <div className="col-lg-8 pe-lg-5">
                            {/* blog details content Start */}
                            <div className="blog-details-content">
                                
                                <PageBody 
                                    body={body}
                                />

                                {/* Post Tag & Share Start */}
                                <div className="flx-between gap-2 mb-40 mt-40">
                                    <TagList 
                                        list={tags}
                                    />
                                    
                                    <SocialList />
                                </div>
                               
                            
                                <CommentPost 
                                    onComment={onAddComment}
                                /> 
                                
                                <CommentList 
                                    list={comments.list}
                                    total_number={comments.total_number}
                                    onReply={onReply}
                                />
                                
                            </div>
                            {/* blog details content End*/}
                        </div>
                        <div className="col-lg-4">
                            {/* blog Sidebar Start */}
                            <BlogSidebar 
                                latest_blog_list={latest_blog_list}
                                category_list={category_list}
                            />
                            {/* blog Sidebar End*/}
                        </div>
                    </div>
                </div>
            </section>

            {/* Article Section */}
            <ArticleList 
                list={latest_blog_list}
            />
            
        </>

    );
}

export default BlogDetailsSidebar;