
import {
    post
} from '../utils/request'

// Axios Get Call - Get all jokes categories.
export const sendCode = async (param = {}) => {
    try {
        const response = await post("/verifycode/sendCode",param);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}