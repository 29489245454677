
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'react-message-popup'

export default function CommentPost({
    onComment
}) {
    const [comment,setComment] = useState("")
  const { t } = useTranslation()

  const handleClick = () => {
    if(!comment) {
        message.warning(t("comment.is.empty"))
        return ;
    }

    onComment(comment)
  }

  return (
    <div className="comment-form mt-64 mb-64" id="comment-box">
        <h5 className="mb-32">{t("post.a.comment")}</h5>
        {/* <form action={onSubmit}> */}
        <div className="row gy-4">
            <div className="col-sm-12">
                <label
                    htmlFor="comment"
                    className="form-label mb-2 font-18 font-heading fw-600"
                >
                    {t("your.comment")}
                </label>
                <textarea
                    className="common-input common-input--grayBg border"
                    id="comment"
                    placeholder={t("type.your.comment")}
                    
                    onChange={e => {
                        setComment(e.target.value)
                    }}
                />
            </div>
            <div className="col-sm-12">
           
            </div>
            <div className="col-sm-12">
            <button className="btn btn-main btn-lg pill" onClick={handleClick}>
                {" "}
                {t("submit")}
                <span className="icon icon-right line-height-1 font-20">
                <i className="las la-arrow-right" />
                </span>
            </button>
            </div>
        </div>
        {/* </form> */}
    </div>
  )
}
