import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { icons } from "../../constants/icons";
import OrderDownloadCard from "./OrderDownloadCard";
import Pagination from '../pagination'

const CustomerOrderList = ({
  list,
  total_number
}) => {
  
  const { t } = useTranslation() 


  return (
    <div className="dashboard-body__content">
      <div className="row gy-4">
        <div className="col-lg-12">
          <div className="search-box">
            <input
              type="text"
              className="common-input common-input--lg pill border border-gray-five"
              placeholder={t("search.your.order")}
            />
            <button type="submit" className="btn btn-main btn-icon icon border-0">
              <img src={icons.search} alt="" />
            </button>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="download-wrapper bg-white border border-gray-five">
            {
              list && list.length > 0 && list.map((order,index) => {
                return (
                  <OrderDownloadCard 
                    key={`order-${index}`}
                    order={order}
                  />
                )
              })
            }


          </div>

          <Pagination 
            totalNumber={total_number}
            onFilter={params => {
              console.log('pagination is', params)
            }}
          />
        </div>
      </div>
    </div>

  );
}

export default CustomerOrderList;