
import { Link } from "react-router-dom"
import { locale } from "../../utils/time"
import { icons } from "../../constants/icons"
import { t } from "i18next"
const CommentCard = ({ comment,onReply }) => {

    return (
        <li className="comment-list__item d-flex align-items-start gap-sm-4 gap-3">
            <div className="comment-list__thumb flex-shrink-0">
                <img
                    src={comment?.avatar}
                    className="cover-img"
                    alt=""
                />
            </div>
            <div className="comment-list__content">
                <div className="flx-between gap-2 align-items-start">
                    <div>
                        <h6 className="comment-list__name font-18 mb-sm-2 mb-1">
                            {comment?.nickname}
                        </h6>
                        <span className="comment-list__date font-14">
                            {/* Jan 21, 2024 at 11:25 pm */}
                            {locale(comment?.create_time, "ddd, MMM D, YYYY h:mm A")}
                        </span>
                    </div>
                    <Link
                        className="comment-list__reply fw-500 flx-align gap-2 hover-text-decoration-underline"
                        to="#comment-box"
                        onClick={() => {
                            onReply(comment?.id)
                        }}
                    >
                        {t("reply")}
                        <span className="icon">
                            <img
                                src={icons.reply_icon}
                                alt=""
                            />
                        </span>
                    </Link>
                </div>
                <p className="comment-list__desc mt-3">
                    {comment?.content }
                </p>
            </div>
        </li>
    )
}

export default CommentCard