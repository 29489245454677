import React from 'react'
import {QRCodeSVG} from 'qrcode.react';
import { SIZES } from '../../constants';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
export default function WechatPay({
    logo,
    productName,
    price,
    content,
    handleRefresh
}) {

    const { i18n } = useTranslation()

    function renderInfo() {
        return (
            <div
                style={{
                    display:'flex',
                    flexDirection: 'column'
                }}
            >
                {/* logo  */}
                <img src={logo} alt='logo' style={{ width: 110 ,height: 30}} />

                {/* product name  */}
                <div
                    style={{
                        display:'flex',
                        marginTop: SIZES.radius,
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                           fontSize: SIZES.h3,

                        }}
                    >{productName}</div>

                    {/* price */}
                    <div>{`${i18n.language == "zh" ? "￥" : "$" } `} {price}</div>
                </div>
                
                
            </div>
        )
    }

    function renderQrcode() {
        return (
            <div
                style={{
                    marginLeft: SIZES.padding,
                    display:'flex',
                    flexDirection:'column'
                }}
            >
                <QRCodeSVG 
                    value={content}
                    onClick={() => {

                    }}
                />

                <button className="btn btn-main btn-sm pill" onClick={handleRefresh} style={{
                    marginTop: SIZES.radius
                }}>
                    {" "}
                    {t("refresh")}
                    <span className="icon icon-right line-height-1 font-20">
                    {/* <i className="las la-arrow-right" /> */}
                    </span>
                </button>
            </div>
        )
    }

  return (
    <div 
        style={{
            display: 'flex',
            flexDirection: 'row'
        }}
    >
        { renderInfo()  }

        { renderQrcode() }
    </div>
  )
}
