import { Link } from "react-router-dom";
import { gradient, icons, shapes, thumbs } from "../constants/icons";
import { tech_list } from "../constants";

const BannerOne = ({ 
    title, 
    description,
    search_placeholder,
    customer_amount,
    customer_title,
    theme_plugin_amount,
    theme_plugin_title
  }) => {
  return (
    <>
      <section className="hero section-bg z-index-1">
        <img
          // src="../assets/images/gradients/banner-gradient.png"
          src={gradient.banner_gradient}
          alt=""
          className="bg--gradient white-version"
        />
        <img
          src={shapes.element_moon1}
          alt=""
          className="element one"
        />
        <img
          src={shapes.element_moon2}
          alt=""
          className="element two"
        />
        <div className="container container-two">
          <div className="row align-items-center gy-sm-5 gy-4">
            <div className="col-lg-6">
              <div className="hero-inner position-relative pe-lg-5">
                <div>
                  <h1 className="hero-inner__title">
                    {/* 2M+ curated digital products */}
                    {title}
                  </h1>
                  <p className="hero-inner__desc font-18">
                    {/* Explore the best premium themes and plugins available for
                    sale. Our unique collection is hand-curated by experts. Find
                    and buy the perfect premium theme today. */}
                    {description}
                  </p>
                  <div className="search-box">
                    <input
                      type="text"
                      className="common-input common-input--lg pill shadow-sm"
                      placeholder={search_placeholder}
                    />
                    <button
                      type="submit"
                      className="btn btn-main btn-icon icon border-0"
                    >
                      <img src={icons.search} alt="" />
                    </button>
                  </div>
                  {/* Tech List Start */}
                  <div className="product-category-list">

                    {
                      tech_list.map((tech,index) => {
                        return (
                          <Link 
                            key={`tech-${index}`}
                            to="/all-product"
                            className="product-category-list__item"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title={tech.title}
                          >
                            <img src={tech.icon} alt="" />
                          </Link>
                        )
                      })
                    }
                    
                  </div>
                  {/* Tech List End */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-thumb">
                <img src={thumbs.banner_img} alt="" />
                <img
                  src={shapes.dots}
                  alt=""
                  className="dotted-img white-version"
                />
                <img
                  src={shapes.dots_white}
                  alt=""
                  className="dotted-img dark-version"
                />
                <img
                  src={shapes.element2}
                  alt=""
                  className="element two end-0"
                />
                <div className="statistics animation bg-main text-center">
                  <h5 className="statistics__amount text-white">{customer_amount}</h5>
                  <span className="statistics__text text-white font-14">
                    {customer_title}
                  </span>
                </div>
                <div className="statistics style-two bg-white text-center">
                  <h5 className="statistics__amount statistics__amount-two text-heading">
                    {theme_plugin_amount}
                  </h5>
                  <span className="statistics__text text-heading font-14">
                    {theme_plugin_title}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerOne;
