import React, { useState } from "react";
import Preloader from "../../helper/Preloader";
import MasterLayout from "../../layout/MasterLayout";
import DashboardStatement from "../../components/DashboardStatement";
import { t } from "i18next";
import { useEffectOnce } from "../../utils/func";
import * as distributorApi from '../../api/distributor'
import DistributorStatistic from "../../components/distributor/DistributorStatistic";
const DistributorOrdersPage = () => {

  const [statistic,setStatistic] = useState({
    amount: 0,                        //累计收益
    customers_num: 0,                 //累计客户
    promotion_order_num: 0,           //订单数量
    settlement_amount: 0,             //结算金额
    today_customers_num: 0,           //今日客户数量
    unsettlement_amount: 0,           //未结算金额
    yesterday_amount: 0,
    yesterday_customers_num: 0,
    yesterday_settlement_amount: 0,
    yesterday_unsettlement_amount: 0
  })

  const [result,setResult] = useState({
    list: [],
    total_number: 0
  })

  useEffectOnce(() => {
    loadStatistic();
    loadList({})
  },[])

  async function loadStatistic(){
    const response = await distributorApi.statistics()
    if(response && response.code === 0 ) {
      setStatistic(response.result.info)
    }
  }

  async function loadList(params = {}) {
    const response = await distributorApi.orders(params)
    console.log('分销list',response)
    if(response && response.code === 0) {
      setResult(response.result)
    }
  }

  return (

    <>
      <MasterLayout
        seo_title={t("distributor.order.list")}
      >
        {/* Preloader */}
        <Preloader />

        {/* DashboardStatement */}
        <DistributorStatistic
          amount={statistic.amount}
          customers_num={statistic.customers_num}
          promotion_order_num={statistic.promotion_order_num}
          settlement_amount={statistic.settlement_amount}
          unsettlement_amount={statistic.unsettlement_amount}
          today_customers_num={statistic.today_customers_num}
          yesterday_amount={statistic.yesterday_amount}
          yesterday_customers_num={statistic.yesterday_customers_num}
          yesterday_settlement_amount={statistic.yesterday_settlement_amount}
          yesterday_unsettlement_amount={statistic.yesterday_unsettlement_amount}
          list={result.list}
          total_number={result.total_number}
          onFilter={params => loadList(params)}
        />

      </MasterLayout>

    </>
  );
};

export default DistributorOrdersPage;
