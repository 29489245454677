import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
export default function TagList({
    list 
}) {

    const  { t } = useTranslation()

  return (

    <div className="post-tag flx-align gap-3">
        <span className="post-tag__text text-heading fw-500">
            {t("post.tag")}:{" "}
        </span>
        <ul className="post-tag__list flx-align gap-2">
            {
            list && list.map((tag,index) => {
                return (
                <li className="post-tag__item" key={`tag-${index}`}>
                    <Link
                        to={`/blogs?tag=${tag}`}
                        className="post-tag__link font-14 text-heading pill fw-500"
                    >
                        {tag}
                    </Link>
                    </li>
                )
            })
            }
        </ul>
    </div>
  )
}
