

import { useState } from "react";
import { Link } from "react-router-dom";
import { dates, ratings, sort_array } from "../../constants";
import ProductVerticalCard from "./verticalCard";
import Loading from "../Loading";
import Pagination from "../pagination";
import { useTranslation } from "react-i18next";
import { icons } from "../../constants/icons";
const AllProduct = ({
  categoryList,
  goodsList,
  totalNumber,
  onFilter,
  loading
}) => {
  const { t } = useTranslation()

  const [activeButton, setActiveButton] = useState("grid-view");
  const [filter, setFilter] = useState(false)
  const [sort,setSort] = useState({
    id:0,
    title: ""
  })

  const handleSort = (item) => {
    console.log('handle sort clicked',item)
    let param = {}
    if(item.id > 0 ) {
      param.sort_type = item.id 
    }
    onFilter(param)
  }

  const handleCategoryChange = (categoryId) => {

    let param = {}
    if(categoryId > 0 ) {
      param.category_ids = [categoryId]
    }
    onFilter(param)
  }

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleFilter = () => {
    setFilter(!filter);
  };

  function renderCategoryList() {
    return (
      <div className="filter-sidebar__item">
        <button
          type="button"
          className="filter-sidebar__button font-16 text-capitalize fw-500"
        >
          {t("categories")}
        </button>
        <div className="filter-sidebar__content">
          <ul className="filter-sidebar-list">

            {
              categoryList && categoryList.length > 0 && categoryList.map((item,index) => {
                return (
                  <li className="filter-sidebar-list__item" key={`category-${index}`}>
                    <div className="filter-sidebar-list__text" onClick={() => { handleCategoryChange(item.id)}}>
                      {item.name} <span className="qty">{item.count}</span>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    )
  }

  function renderGoodsList() {

    if(loading) {
      return <Loading />
    }

    return (
      <div className="row gy-4 list-grid-wrapper">
        {
          goodsList && goodsList.map((item,index) => {
            return (
              <ProductVerticalCard 
                key={`vcard-${index}`}
                className="col-xl-4 col-sm-6"
                good={item}
              />
            )
          })
        }
      </div>
    )
  }


  return (
    <section className={`all-product padding-y-120 ${activeButton === "list-view" ? "list-view" : ""
      }`}>
      <div className="container container-two">
        <div className="row">
          <div className="col-lg-12">
            <div className="filter-tab gap-3 flx-between">
              <button
                type="button"
                className="filter-tab__button btn btn-outline-light pill d-flex align-items-center"
              >
                <span className="icon icon-left">
                  <img src={icons.filter} alt="" />
                </span>
                <span className="font-18 fw-500">{t("filters")}</span>
              </button>
              <ul
                className="nav common-tab nav-pills mb-0 gap-lg-2 gap-1 ms-lg-auto"
                id="pills-tab"
                role="tablist"
              >
                {
                  sort_array.map((item,index) => {
                    return (
                      <li className="nav-item" role="presentation" key={`sort-${index}`}>
                          <button
                            className={`nav-link ${sort.id == item.id ? "active" : ""}`}
                            id="pills-product-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-product"
                            type="button"
                            role="tab"
                            aria-controls="pills-product"
                            aria-selected="true"
                            onClick={() => handleSort(item)}
                          >
                            {t(item.title)}
                          </button>
                        </li>
                    )
                  })
                }
              </ul>
              <div className="list-grid d-flex align-items-center gap-2">
                <button
                  className={`list-grid__button list-button d-sm-flex d-none text-body ${activeButton === "list-view" ? "active" : ""
                    }`}
                  onClick={() => handleClick("list-view")}
                >
                  <i className="las la-list" />
                </button>
                <button
                  className={`list-grid__button grid-button d-sm-flex d-none  text-body ${activeButton === "grid-view" ? "active" : ""
                    }`}
                  onClick={() => handleClick("grid-view")}
                >
                  <i className="las la-border-all" />
                </button>
                <button className="list-grid__button sidebar-btn text-body d-lg-none d-flex" onClick={handleFilter}>
                  <i className="las la-bars" />
                </button>
              </div>
            </div>
            <form action="#" className="filter-form pb-4 d-block">
              <div className="row gy-3">
                <div className="col-sm-4 col-xs-6">
                  <div className="flx-between gap-1">
                    <label htmlFor="tag" className="form-label font-16">
                      {t("tag")}
                    </label>
                    <button type="reset" className="text-body font-14">
                      {t("clear")}
                    </button>
                  </div>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="common-input border-gray-five common-input--withLeftIcon"
                      id="tag"
                      placeholder={t("search.by.tag")}
                    />
                    <span className="input-icon input-icon--left">
                      <img src={icons.search_two} alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-sm-4 col-xs-6">
                  <div className="flx-between gap-1">
                    <label htmlFor="Price" className="form-label font-16">
                      
                      {t("price")}
                    </label>
                    <button type="reset" className="text-body font-14">
                      
                      {t("clear")}
                    </button>
                  </div>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="common-input border-gray-five"
                      id="Price"
                      placeholder={t("price.range.placeholder")}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="flx-between gap-1">
                    <label htmlFor="time" className="form-label font-16">
                      {t("time.frame")}
                    </label>
                    <button type="reset" className="text-body font-14">
                      {t("clear")}
                    </button>
                  </div>
                  <div className="position-relative select-has-icon">
                    <select id="time" className="common-input border-gray-five" defaultValue={1}>
                      {
                        dates.map((item,index) => {
                          return <option value={1} key={`sort-${index}`}>
                            {t(item.title)}
                          </option>
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-xl-3 col-lg-4">
            {/* ===================== Filter Sidebar Start ============================= */}
            <div className={`filter-sidebar ${filter && "show"}`}>
              <button
                type="button"
                className="filter-sidebar__close p-2 position-absolute end-0 top-0 z-index-1 text-body hover-text-main font-20 d-lg-none d-block"
                onClick={handleFilter}>
                <i className="las la-times" />
              </button>
              { renderCategoryList() }
              <div className="filter-sidebar__item">
                <button
                  type="button"
                  className="filter-sidebar__button font-16 text-capitalize fw-500"
                >
                  {t("rating")}
                </button>
                <div className="filter-sidebar__content">
                  <ul className="filter-sidebar-list">
                    {
                      ratings.map((item,index) => {
                        return (
                          <li className="filter-sidebar-list__item" key={`rating-${index}`} onClick={() => console.log('handle rating')}>
                            <div className="filter-sidebar-list__text">
                              <div className="common-check common-radio">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio"
                                  id="veiwAll"
                                />
                                <label className="form-check-label" htmlFor="veiwAll">
                                  {t(item.title)}
                                </label>
                              </div>
                              <span className="qty">({item.count})</span>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
              <div className="filter-sidebar__item">
                <button
                  type="button"
                  className="filter-sidebar__button font-16 text-capitalize fw-500"
                >
                  {t("date.updated")}
                </button>
                <div className="filter-sidebar__content">
                  <ul className="filter-sidebar-list">
                    {
                       dates.map((item,index) => {
                        return (
                          <li className="filter-sidebar-list__item" key={`date-${index}`} onClick={() => console.log('handle date change')}>
                              <div className="filter-sidebar-list__text">
                                <div className="common-check common-radio">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio"
                                    id="anyDate"
                                  />
                                  <label className="form-check-label" htmlFor="anyDate">
                                    {t(item.title)}
                                  </label>
                                </div>
                                <span className="qty">{item.count}</span>
                              </div>
                          </li>
                        )
                       })
                    }
                    
                  </ul>
                </div>
              </div>
            </div>
            {/* ===================== Filter Sidebar End ============================= */}
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-product"
                role="tabpanel"
                aria-labelledby="pills-product-tab"
                tabIndex={0}
              >
                  { renderGoodsList() }

                  <Pagination 
                    totalNumber={totalNumber}
                    onFilter={onFilter}
                  />
                  {/* Pagination Start */}
                  {/* { renderPagination() } */}
                {/* Pagination End */}
              </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllProduct;
