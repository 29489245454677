

import { Link } from "react-router-dom";
import { getGoodDetailUrl } from "../../utils";
import { t } from "i18next";
import { icons } from "../../constants/icons";

const ProductBestSellCard = ({ good }) => {

    const linkurl = getGoodDetailUrl(good.sn,undefined)

    return (
        <div className="product-item shadow-sm overlay-none">
            <div className="product-item__thumb d-flex max-h-unset">
            <Link to={linkurl} className="link w-100">
                <img
                src={good.img}
                alt=""
                className="cover-img"
                />
            </Link>
            </div>
            <div className="product-item__content">
            <h6 className="product-item__title">
                <Link to={linkurl} className="link">
                   { good.title }
                </Link>
            </h6>
            <div className="product-item__info flx-between gap-2">
                <span className="product-item__author">
                {t("by")}
                <Link
                    to="/profile"
                    className="link hover-text-decoration-underline"
                >
                    -
                </Link>
                </span>
                <div className="flx-align gap-2">
                <h6 className="product-item__price mb-0">${good?.price}</h6>
                <span className="product-item__prevPrice text-decoration-line-through">
                    ${good.line_price}
                </span>
                </div>
            </div>
            <div className="product-item__bottom flx-between gap-2">
                <div>
                <span className="product-item__sales font-16 mb-2">
                   {`${good?.sale_num} `} {t("sales")}
                </span>
                <ul className="star-rating gap-2">
                    <li className="star-rating__item font-16">
                    <i className="fas fa-star" />
                    </li>
                    <li className="star-rating__item font-16">
                    <i className="fas fa-star" />
                    </li>
                    <li className="star-rating__item font-16">
                    <i className="fas fa-star" />
                    </li>
                    <li className="star-rating__item font-16">
                    <i className="fas fa-star" />
                    </li>
                    <li className="star-rating__item font-16">
                    <i className="fas fa-star" />
                    </li>
                </ul>
                </div>
                <div className="flx-align gap-2">
                <Link
                    to={linkurl}
                    className="btn btn-outline-light download-icon btn-icon btn-icon--sm pill"
                >
                    <span className="icon">
                    <img
                        src={icons.download}
                        alt=""
                        className="white-version"
                    />
                    <img
                        src={icons.download_white}
                        alt=""
                        className="dark-version"
                    />
                    </span>
                </Link>
                <Link
                    to={linkurl}
                    className="btn btn-outline-light pill"
                >
                    {t("buy")}
                </Link>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ProductBestSellCard