
import dayjs from "dayjs"
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);


export const timeAgo = (time) => {
    return  dayjs().to(dayjs(time * 1000))
}

export const locale = (time,language = "zh") => {
   
    if(time === 0 ) {
        return "-"
    }

    let format = "YYYY-MM-DD"
    if(language !== "zh") {
        format = "MMMM DD,YYYY";
    }
    return dayjs(time * 1000).format(format)
}